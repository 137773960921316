import { Directive, Input, OnInit, ElementRef, OnDestroy, ViewContainerRef } from "@angular/core";

import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';

import { AppStateService, UtilsService, HttpService } from 'src/app/services';
import { AuthenticationService } from "../authentication/services/authentication.service";
import { EnvService } from "src/app/services/env.service";
import { DataService } from "src/app/services/data.service";

import { IGNORE_RESOURCE_LIST, USER_MGMT_CNST } from '../../constants/proj.cnst';

@Directive({
  selector: '[permissions]'
})

export class PermissionsDirective implements OnInit, OnDestroy {

  @Input('resourceName') resourceName: string;
  @Input('permission') permission: string;

  currentApplication: string;

  subscriptions: Subscription[] = [];

  userPermissions: any = {};

  constructor(
    private appStateSrv: AppStateService,
    private elementRef: ElementRef,
    private utilSrv: UtilsService,
    private httpSrv: HttpService,
    private dataSrv : DataService,
    private viewContainer : ViewContainerRef,
    private envService : EnvService,
    private authSrv: AuthenticationService
  ) { }

  ngOnInit() {
    // console.log("Permission Directive on  resourceName:: " + this.resourceName);
    this.currentApplication = USER_MGMT_CNST.CURRENT_APPLICATION;
    this.viewContainer.clear();

    let hasPermissionsLoaded = this.appStateSrv.getPermissionsLoaded();
    let isPermissionRequestInProcess = this.appStateSrv.getInProcess();
  
    this.userPermissions = this.appStateSrv.getUserPermissions();


    if (hasPermissionsLoaded) {
      this.checkPermissions();
    }
    else if (isPermissionRequestInProcess) {
      this.registerCallBack();
    }
    else {
      const userId = this.authSrv.getUserId();
      
      if(!userId) {
        this.elementRef.nativeElement.style.display = "none";
        this.viewContainer.clear();
        return;
      }

      this.appStateSrv.setInProcess(true);
      let options = {uriParams: {userId}};
      console.log("fetching user permissions");
      this.httpSrv
        .makeGetApiCall('GET_USER_PERMISSIONS', this.envService.baseUrl, options)
        .subscribe((res: any) => {
          if (!isNullOrUndefined(res.response)) {
            this.appStateSrv.setPermissionsLoaded(true);
            this.userPermissions = res.response && res.response.permissions ? res.response.permissions : {};

            let metaInfo = res.response && res.response.meta ? res.response.meta : {};

            //* Store user permissions in local storage
            //* only for product passport project
            this.utilSrv.setUserPermissionsInLocalStorage(this.userPermissions, metaInfo);

            this.appStateSrv.setUserMetaInfo(metaInfo);
            this.appStateSrv.setUserPermissionsRef(this.userPermissions);
            this.checkPermissions();

            let currentResourceName = this.appStateSrv.getCurrentResourceName();

            if (currentResourceName === 'LOGIN') {
              this.appStateSrv.setCurrentResourceName("USER");
              this.dataSrv.getRoleAndRedirect();
            }

          } else {
            this.utilSrv.showToastMessage("User Not Found", "error");
          }
        })
    }

    const userPermissionsRef = this.appStateSrv.userPermissionsRef.subscribe((response: any) => {
      this.userPermissions = { ...response };
    })

    this.subscriptions.push(userPermissionsRef);
  }

  registerCallBack() {
    // console.log("register callback");
    const permissionsRef = this.appStateSrv.userPermissionsRef.subscribe((response: any) => {
      this.userPermissions = response;
      permissionsRef.unsubscribe();
      this.checkPermissions();
    })
  }

  checkPermissions() {
    // console.log("checkPermissions");
    //*If the directive is checking for actions under a resource
    if (!isNullOrUndefined(this.permission)) {
      // console.log("ResourceName " + this.resourceName + " permissions:: " + this.permission + ", Has Access: " + this.doesResourceHaveValidPermission(this.resourceName, this.permission));
      this.elementRef.nativeElement.style.display = this.doesResourceHaveValidPermission(this.resourceName, this.permission) ? "block" : "none";
      this.viewContainer.clear();
    }
    //*Else we are checking if the resource itself exists on the application
    else {
      // console.log("ResourceName " + this.resourceName + ", Has Access: " + this.doesResourceExistInApplication(this.resourceName));

      this.elementRef.nativeElement.style.display = this.doesResourceExistInApplication(this.resourceName) ? "block" : "none";
      this.viewContainer.clear();
    }
  }

  doesResourceHaveValidPermission(resourceName: string, permission: string): boolean {
    if (this.currentApplication in this.userPermissions) {
      let resSettings = this.userPermissions[this.currentApplication].resources;
      if (resourceName in resSettings) {
        return resSettings[resourceName].actions.indexOf(permission) !== -1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  doesResourceExistInApplication(resourceName: string): boolean {
    if(!(this.currentApplication in this.userPermissions)) {
      return false;
    }
    return this.userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION]["resources"][this.resourceName == IGNORE_RESOURCE_LIST ? 'CUSTOMER' : resourceName] ? true : false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}