import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/enums';
import { InitService } from 'src/app/parsers/init.service';
import { AppStateService } from 'src/app/services';
import { Router, NavigationEnd, Event } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  sidebarState: boolean;
  userPermissions : any = {};

  subscriptions: Subscription[] = [];
  sideConfig: any;
  currentPath: string;

  currentAppName : string = null;
  customerIdPresent:boolean=false;

  constructor(
    private appStateSrv: AppStateService, 
    private initSrv: InitService,
    private router: Router,
    private envSrv : EnvService) { }

  ngOnInit() {
    this.getConfig()
    this.sidebarState = this.appStateSrv.getSidebarState();

    const sideBarStateRef = this.appStateSrv.showSidebarRef.subscribe(res => {
      this.sidebarState = res;
    })

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.router.url 
      }
    });

    this.currentAppName = this.envSrv.headerAppName;

    this.subscriptions.push(sideBarStateRef);
    this.customerIdPresent = !isNullOrUndefined(JSON.parse(localStorage.getItem('metaInfo')).customerId)
  }

  getConfig() {
    const sidebarConfig = {
      configType: Config.SIDEBAR_CONFIG
    }
    this.sideConfig = this.initSrv.getConfig(sidebarConfig.configType);
  }

  toggleSidebar() {
    this.appStateSrv.setSidebarState(!this.sidebarState);
  }

  onListItemClicked(routePath : string) {
    this.appStateSrv.setSidebarState(!this.sidebarState);
    this.router.navigateByUrl(routePath);
  }

  checkForActiveRoute(currentPath : string, routerPath : string) {
    if (!isNullOrUndefined(currentPath) && !isNullOrUndefined(routerPath)) {
      return currentPath.includes(routerPath);
    }
  }

  redirectToApp() {

    let redirectUrl = this.envSrv.appRedirectUrl;

    if (!isNullOrUndefined(redirectUrl)) {
      this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
