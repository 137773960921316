import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { USER_MGMT_CNST } from '../../constants/proj.cnst';
import { UtilsService } from '../../services/utils.service';
import { EnvService } from 'src/app/services/env.service';
import { AppStateService } from 'src/app/services';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    private router: Router,
    private utilSrv: UtilsService,
    private appStateSrv: AppStateService,
    private envSrv: EnvService) { }

  isOneOfPredefinedResources: boolean;


  ngOnInit(): void {

    let userPermissions = this.appStateSrv.getUserPermissions();

    let currentResourceName = this.appStateSrv.getCurrentResourceName();

    // console.log("Current Resource Name in landing page ", currentResourceName);

    this.isOneOfPredefinedResources = USER_MGMT_CNST.PREDEFINED_RESOURCES.indexOf(currentResourceName) !== -1;

    // console.log("is one of predefined resources in landing page ", this.isOneOfPredefinedResources);

    this.appStateSrv.setIsPredefinedResource(this.isOneOfPredefinedResources);

    if (this.utilSrv.isObjectNotNullAndEmpty(userPermissions)) {

      setTimeout(() => {
        //TODO : Considering only first application assigned to user
        //TODO : Generalize when user has been assigned multiple applications
        // let appName = Object.keys(userPermissions)[0];
        let appName = USER_MGMT_CNST.CURRENT_APPLICATION;

        if (!isNullOrUndefined(appName)) {
          let { roleName = null } = userPermissions[appName];

          if (!isNullOrUndefined(roleName)) {

            // console.log("Role Name ", roleName);

            let isUserSuperAdminOrCustAdminOrCSE = (roleName === USER_MGMT_CNST.CUSTOMER_ADMIN_ROLE
              || roleName === USER_MGMT_CNST.SUPER_ADMIN
              || roleName === USER_MGMT_CNST.CUSTOMER_SERVICE_EXECUTIVE) ? true : false;

            // console.log("Is user super admin or cust admin or cse ", isUserSuperAdminOrCustAdminOrCSE);

            let redirectUrl = isUserSuperAdminOrCustAdminOrCSE
              ? this.envSrv.userMgmtRedirectUrl
              : this.envSrv.appRedirectUrl;

            // console.log("Redirect Url ", redirectUrl);

            if (!isNullOrUndefined(redirectUrl)) {
              this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
            }
          } else {

            let redirectUrl = this.envSrv.appRedirectUrl;

            this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
          }
        }
      }, 3000)
    } else {
      console.log("user permissions is empty, unable to redirect");
      this.utilSrv.showToastMessage("User does not have permissions to view the application", "error");
    }
  }
}