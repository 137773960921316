import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST, USER_MGMT_DEFAULT_CONFIG_CNST } from 'src/app/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService, UtilsService, AppStateService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';
import { countryCodes } from '../../../../constants/country-codes';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userData: any;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  isEmailValid : boolean = true;

  @Input() addUserConfig;
  @Input() customerList;

  constants = USER_MGMT_DEFAULT_CONFIG_CNST.ADD_USER_CONST;
  superAdmin: any = USER_MGMT_CNST.SUPER_ADMIN; 

  countryCodeList : any[] = [...countryCodes];

  userForm: FormGroup = new FormGroup({
    // firstName: new FormControl('', [Validators.required]),
    // lastName: new FormControl('', []),
    // username: new FormControl('', [Validators.required]),
    // email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    // mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[9876][0-9]{9}$/)]),
    // customers:  new FormControl('',[])
  });

  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;

  primaryMobileForm = new FormGroup({
		primaryMobileNo: new FormControl(undefined, [Validators.required]),
	});

  templateTypes: any = {};
  userPermission:any;
  userMetaInfo: any;
  isCustomer: any;

  isPrimaryMobileNoValid : boolean = true;
  currentCountryCode : string = "in";

  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService : EnvService,
    private appState: AppStateService,
    private utilSrv: UtilsService,
  ) { }

  ngOnInit() {
    this.userData = [];
    this.userPermission = this.appState.getUserPermissions();
    this.userMetaInfo = this.appState.getUserMetaInfo();
    
    this.isCustomer = this.userMetaInfo && (this.userMetaInfo['customerId']|| this.userMetaInfo['customerName']) ? true : false;

    if (!this.isCustomer) {
      this.userForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', []),
        username: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
        customers: new FormControl('', [Validators.required])
      });
    } else {
      this.userForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', []),
        username: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      });
    }
  }

  // TODO: move this logic to directive.
  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onlyDigit(event: any) {
    const pattern = /[1-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilsSrv.isEmailValid(this.emailPattern, inputStr);
  }

  // Add user to db.
  handleAdd() {

    if (this.userForm.invalid) {
      // TODO: handle errors.
      return;
    }

    //* If it is super admin need to check selected customer
    if(!this.isCustomer && (!this.userForm.value.customers || !this.userForm.value.customers.customerId || !this.userForm.value.customers.customerName)){
        this.utilsSrv.showToastMessage("Please select customer","error");
        return;
    }
    
    //* Create post data for add user
    const postdata = this.postDataCreationForAddUser();

    //* Api call for add user
    this.httpSrv.makePostApiCall('ADD_USER', postdata, this.envService.baseUrl)
      .subscribe(res => {
        if (res.result) {
          this.utilsSrv.showToastMessage(
            "Successfully added new user",
            "success"
          );
          this.activeModal.close({ updateTable: true });
        } else {
          this.utilsSrv.showToastMessage(
            "Error occured while creating user",
            "error"
          );
        }
      }, err => {

        let { error } = err;

        if (this.utilSrv.isObjectNotNullAndEmpty(error)) {
          let { message } = error;

          if (message) {
            message.includes("'users.mobile_number'") ? message = "Mobile Number Already Exists" : message;
            this.utilSrv.showToastMessage(message, "error");
          } else {
            this.utilSrv.showToastMessage(
              "Error occured while creating user",
              "error"
            );
          }

        } else {
          this.utilSrv.showToastMessage(
            "Error occured while creating user",
            "error"
          );
        }
      })
  }

  //* post data creation for add user
  postDataCreationForAddUser(){
    const postdata = {
      metaInfo: {
        "username": this.userForm.value.username,
        "firstName": this.userForm.value.firstName,
        "lastName": this.userForm.value.lastName,
        "email": this.userForm.value.email,
        "mobileNo": this.primaryMobileForm.controls['primaryMobileNo'].value.e164Number,
        "customerId": "",
        "customerName": "" 
      }
    }
    
    //* super admin get from selected customer / for customers fetch from login details
    if (!this.isCustomer && this.userForm.value.customers && this.userForm.value.customers.customerId && this.userForm.value.customers.customerName) {
      postdata['metaInfo']['customerId'] = this.userForm.value.customers.customerId;
      postdata['metaInfo']['customerName'] = this.userForm.value.customers.customerName;
    } else {
      this.userMetaInfo = this.appState.getUserMetaInfo();
      postdata['metaInfo']['customerId'] = this.userMetaInfo['customerId'];
      postdata['metaInfo']['customerName'] = this.userMetaInfo['customerName'];
    }

    return postdata;
  }

  onPaste(event: ClipboardEvent, key: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z]/g, '');
    setTimeout(() => {
      trimmedText = trimmedText.substr(0, 25);
      this.userForm.patchValue({
        [key]: trimmedText
      });
    }, 1);
  }

  resetField(event : Country) {
    this.isPrimaryMobileNoValid = true;
    this.primaryMobileForm.controls.primaryMobileNo.setValue(null);
    this.currentCountryCode = event.iso2;
  }
  
  checkLength(event: any) {
    let value = event.target.value;

    if (value.length > 0) {
      this.isPrimaryMobileNoValid = this.primaryMobileForm.invalid ? false : true;
    } else {
      this.isPrimaryMobileNoValid = true;
    }
  }
}


