import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { isNullOrUndefined } from "util";
import { UtilsService } from ".";
import { EnvService } from "./env.service";

@Injectable({
  "providedIn" : "root"
})

export class CsrfTokenService {

  constructor(
    private httpSrv : HttpService,
    private utilSrv : UtilsService,
    private envService : EnvService) { }

  //* Create csrf token from keycloak access token
  generateCsrfToken(accessToken : string) : Promise<any> {

    let options = {
      headers: {
        'Authorization': "Bearer " + accessToken
      }
    }

    console.log("Calling Csrf token ....");

    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('GET_CSRF_TOKEN', this.envService.baseUrl, options)
        .subscribe((response: any) => {
          resolve(response);
        }, err => {
          this.utilSrv.showToastMessage("Something went wrong. Please try again later", "error");
          console.log(err);
          resolve({});
        })
    })

    return promise;
  }

  doesCsrfTokenExist() : boolean {
    let existingToken = localStorage.getItem('csrfToken');
    return !isNullOrUndefined(existingToken) ? true : false;
  }

  getCsrfToken() : string | null {
    return localStorage.getItem('csrfToken');
  }
}