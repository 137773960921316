import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { isNullOrUndefined } from "util";
import { USER_MGMT_CNST } from "../constants";
import { AuthenticationService } from "../modules/authentication/services/authentication.service";
import { AppStateService, HttpService } from "../services";
import { CsrfTokenService } from "../services/csrf-token.service";
import { DataService } from "../services/data.service";
import { EnvService } from "../services/env.service";
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {

  userPermissions: any;

  constructor(
    protected readonly router: Router,
    private utilSrv: UtilsService,
    private authSrv: AuthenticationService,
    private appStateSrv: AppStateService,
    private httpSrv: HttpService,
    private dataSrv : DataService,
    private csrfTokenSrv : CsrfTokenService,
    private envService : EnvService,
    protected readonly keyCloakSrv: KeycloakService,
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
  ):
    Promise<Observable<boolean | UrlTree> |
      Promise<boolean | UrlTree> |
      boolean |
      UrlTree | any> {
    // console.log("URL:: ",next.url, ", Data:: ", next.data);

    const loggedIn = this.authSrv.isLoggedIn() || await this.keyCloakSrv.isLoggedIn();
    const resource = next.data.resource || '';

    //* Force the user to log in if currently unauthenticated.
    if (!loggedIn) {
      return this.handleUnAuthorizedAccess("Please login to view requested page.", ['', 'authentication', 'login']);
    }

    //* Extract the token from keycloak
    const accessToken = this.authSrv.getToken() || await this.keyCloakSrv.getToken();

    //* Get refresh token from keycloak
    const refreshToken = this.authSrv.getRefreshToken() || this.keyCloakSrv.getKeycloakInstance().refreshToken;

    if (!isNullOrUndefined(accessToken)) {


        //* Generate csrf token if it is not loaded.
        if (!this.csrfTokenSrv.doesCsrfTokenExist()) {
          let response = await this.csrfTokenSrv.generateCsrfToken(accessToken);

          //* If keycloak token validation failed redirect to login page
          if (Object.keys(response).length === 0) {
            return this.handleUnAuthorizedAccess("Please login to view requested page.", ['', 'authentication', 'login']);
          }

          this.setCsrfToken(response);
          this.dataSrv.setLoggedInUserInfo(response);
          // this.appStateSrv.setIsTokensLoaded(true);
      }
    } else {
      return this.handleUnAuthorizedAccess("Please login to view requested page.", ['', 'authentication', 'login']);
    }

    const userId = this.authSrv.getUserId();

    let hasPermissionsLoaded = this.appStateSrv.getPermissionsLoaded();
    let isPermissionRequestInProcess = this.appStateSrv.getInProcess();

    this.userPermissions = this.appStateSrv.getUserPermissions();

    if (hasPermissionsLoaded) {
      return this.checkPermissions(resource);
    } else if (isPermissionRequestInProcess) {
      return this.registerCallBack(resource);
    } else {
      console.log("Fetching user permissions from login component");
      this.appStateSrv.setInProcess(true);
      let options = { uriParams: { userId } };
      return new Promise((resolve, reject) => {
        this.httpSrv
          .makeGetApiCall('GET_USER_PERMISSIONS', this.envService.baseUrl, options)
          .subscribe((res: any) => {
            if (!isNullOrUndefined(res.response)) {
              this.appStateSrv.setInProcess(false);
              this.appStateSrv.setPermissionsLoaded(true);
              this.userPermissions = res.response && res.response.permissions ? res.response.permissions : {};
        
              let metaInfo = res.response && res.response.meta ? res.response.meta : {};
              this.appStateSrv.setUserMetaInfo(metaInfo);
              this.appStateSrv.setUserPermissionsRef(this.userPermissions);
              this.appStateSrv.setIsTokensLoaded(true);

              //* Store user permissions in local storage
              //* only for product passport project
              this.utilSrv.setUserPermissionsInLocalStorage(this.userPermissions, metaInfo);

              resolve(this.checkPermissions(resource));
            } else {
              resolve(this.handleUnAuthorizedAccess());
            }
          }, error => {
            resolve(this.handleUnAuthorizedAccess());
          })
      })
    }
  }

  setCsrfToken(csrfTokenResponse: any) {
    let { response } = csrfTokenResponse;

    if (!isNullOrUndefined(response)) {
      let { tokens } = response;

      if (!isNullOrUndefined(tokens)) {
        let { csrf } = tokens;

        if (isNullOrUndefined(localStorage.getItem('csrfToken'))) {
          localStorage.setItem('csrfToken', csrf);
        }
      }
    }
  }

  handleUnAuthorizedAccess(message: string = "You don't have permissions to view the requested resource", route: string[] = ['', 'errors', 'access-denied']) {
    this.utilSrv.showToastMessage(message, "error");
    this.router.navigate(route);
    return false;
  }
  registerCallBack(resource: string) {
    return new Promise((resolve, reject) => {
      const permissionsRef = this.appStateSrv.userPermissionsRef.subscribe((response: any) => {
        this.userPermissions = response;
        permissionsRef.unsubscribe();
        resolve(this.checkPermissions(resource));
      })
    })

  }

  // TODO: Refactor this. Move all the constants to proj.cnst file
  getRedirectionRoute(): string[] {
    const routeResourceMapping = {
      'USER': ['', 'users'],
      'APPLICATION': ['', 'applications'],
      'RESOURCE': ['', 'resources'],
      "ROLE": ['', 'roles']
    }

    const resourceOrder = {
      'USER': 1,
      'ROLE': 2,
      'APPLICATION': 3,
      'RESOURCE': 4
    }

    if (!this.userPermissions) {
      return ['', 'errors', 'access-denied'];
    }

    const resources = this.userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION].resources || {};
    let resourceList = Object.keys(resources);
    resourceList = resourceList.sort((a, b) => {
      if (!resourceOrder[a] && resourceOrder[b]) {
        return 1;
      }

      if (resourceOrder[a] && !resourceOrder[b]) {
        return -1;
      }

      if (!resourceOrder[a] && !resourceOrder[b]) return 0;
      return resourceOrder[a] - resourceOrder[b];
    });
    const nextResource = resourceList.length ? resourceList[0] : null;

    if (!nextResource) return ['', 'errors', 'access-denied'];

    const routeForResource = routeResourceMapping[nextResource] || ['', 'errors', 'access-denied'];
    return routeForResource;
  }

  checkPermissions(resource: string) {
    const hasApplicationAccess = this.doesUserHasApplicationAccess();
    // const appName = this.getCurrentApplicationName();

    const appName = USER_MGMT_CNST.CURRENT_APPLICATION;
    const redirectUrl = this.envService.appRedirectUrl;

    console.log("Has application access ", hasApplicationAccess);
    // if (!hasApplicationAccess) return this.handleUnAuthorizedAccess();

    if (!hasApplicationAccess) {
      setTimeout(() => {
        this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
      }, 3000)
    } 

    console.log("resource:: ", resource);

    this.appStateSrv.setCurrentResourceName(resource);

    if (!resource) return true;

    if (!isNullOrUndefined(appName) && appName === USER_MGMT_CNST.CURRENT_APPLICATION) {
      const hasResourceAccess = this.doesUserHasResourceAccess(resource);

      if (hasResourceAccess) {
        return true;
      }

      const nextResource = this.getRedirectionRoute();
      console.log("Next Resource:: ", nextResource);

      this.router.navigate(nextResource);
      return false;
    }

    return false;
  }

  doesUserHasPermissions(): boolean {
    return !!this.userPermissions;
  }

  doesUserHasResourceAccess(resourceName: string): boolean {
    if (!this.userPermissions) return false;

    const currentApplicationPermissions = this.userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION] || { resources: {} };

    return resourceName in currentApplicationPermissions.resources;
  }

  doesUserHasApplicationAccess(): boolean {
    // let appName = this.getCurrentApplicationName();
    let appName = USER_MGMT_CNST.CURRENT_APPLICATION;

    if (!isNullOrUndefined(appName)) {
      return !!this.userPermissions && appName in this.userPermissions;
    }

    return false;
  }

  getCurrentApplicationName() : string | null {
    let isPermissionsNotNullAndEmpty = this.utilSrv.isObjectNotNullAndEmpty(this.userPermissions);

    if (isPermissionsNotNullAndEmpty) {

      //TODO : Considering only first application assigned to user
      //TODO : Generalize when user has been assigned multiple applications
      let appName = Object.keys(this.userPermissions)[0];

      return appName;
    }

    return null;
  }
}