import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TargetTreatmentComponent } from './target-treatment.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/modules/shared/shared.module';

const routes = [
  { path: 'list', component: TargetTreatmentComponent },
  { path: '', redirectTo: "list", pathMatch: "full" }
  // { path: 'list', component: AdministratorsListComponent },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AccordionModule,
    SharedModule,
  ],
  declarations: [TargetTreatmentComponent]
})
export class TargetTreatmentModule { }
