import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService, AppStateService, UtilsService } from 'src/app/services';
import { USER_MGMT_CNST } from 'src/app/constants';
import { NgForm } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add-or-view-profile',
  templateUrl: './add-or-view-profile.component.html',
  styleUrls: ['./add-or-view-profile.component.scss']
})
export class AddOrViewProfileComponent implements OnInit, OnDestroy {

  profileData: any = {};

  isFormNotValid: boolean = true;
  isNameTouched: boolean = false;
  applicationList: any[] = [];
  userMetaInfo: any;
  operation:string;

  isRoleNameNotValid : boolean = false;
  subscriptions : Subscription[] = [];

  constructor(
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private dataSrv : DataService,
    private router: Router,
    private route : ActivatedRoute) { }

  ngOnInit() {

    let isProfileDataExists = this.utilsSrv.isObjectNotNullAndEmpty(this.appStateSrv.getProfileData());

    const queryParamRef = this.route.queryParams.subscribe(async (params : Params) => {
      let roleId = params['roleId'];
      let operation = params['operation'];

      this.operation = !isNullOrUndefined(operation) ? operation : this.operation;

      if (!isProfileDataExists) {

        let options = {
          'uriParams': {
            roleId
          }
        }

        if (operation === 'view') {

          let roleInfo = await this.dataSrv.getRoleInfoById(options);

          if (this.utilsSrv.isObjectNotNullAndEmpty(roleInfo)) {
            let { response } = roleInfo;

            if (this.utilsSrv.isObjectNotNullAndEmpty(response)) {
              this.buildProfileData(response);
            }
          }
        } 
      }
    })

    this.subscriptions.push(queryParamRef);

    if (isProfileDataExists) {

      let { operation, info } = this.appStateSrv.getProfileData();

      this.operation = operation;

      if (operation === 'view') {
        this.buildProfileData(info);
      }

    }

    this.userMetaInfo = this.appStateSrv.getUserMetaInfo();
    this.loadApplications();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription : Subscription) => subscription.unsubscribe());
  }
  
  loadApplications() {
    this.httpSrv
          .makeGetApiCall('APP_LIST',this.envService.baseUrl)
          .subscribe((response : any) => {
           this.applicationList = response;
          }, err => this.applicationList = []);
  }


  // inputHasOnlyAlphabets(event: any) {
  //   const pattern = /^[ A-Za-z0-9_@./#()]*$/;
  //   let inputText = event.target.value;

  //   this.isRoleNameNotValid = inputText.trim().length === 0 ? true : false;

  //   this.isFormNotValid = pattern.test(inputText) && inputText.length > 0 ? false : true;
  //   this.isNameTouched = true;
  // }

  inputHasOnlyAlphabets(event: any) {
    let inputText = event.target.value;
    this.isNameTouched = true;
    // this.isFormNotValid = inputText.length > 0 ? false : true;
    this.onPaste(inputText);
  }
  onlyAlphabet(event: any) {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  space(event: any){
    if(event.target.selectionStart === 0 && event.code === 'Space'){
      event.preventDefault();
    }
  }
  onPaste(event:any){
    const pattern = /^[a-zA-Z0-9\s]+$/;
    let inputText = event
    this.isFormNotValid = pattern.test(inputText) ? false : true;
  }
  onFormSubmit(form: NgForm) {
    let { OPERATION_TYPES } = USER_MGMT_CNST; 

    //*If description was not entered set it to blank string
    form.value.description = isNullOrUndefined(form.value.description) ? '' : form.value.description;
    let customerDetails = {};
    if(this.userMetaInfo && this.userMetaInfo['customerId'] && this.userMetaInfo['customerName']){
      customerDetails = {
        customerId: this.userMetaInfo['customerId'],
        customerName: this.userMetaInfo['customerName']
      }
    }else{
      customerDetails = {
        customerId: null,
        customerName: null
      }
    }
    
    this.addProfile(form.value, customerDetails);
    form.reset();
  }

  addProfile(profileInfo: any,customerDetails) {
    let { name, description, application } = profileInfo;

    let postData = {
      "meta": {
        roleName: name,
        description,
        applicationId: application,
        customerId: customerDetails['customerId']
      }
    };
    this.httpSrv
      .makePostApiCall("ADD_PROFILE", postData, this.envService.baseUrl)
      .subscribe(
        (response: any) => {
          if (response.statusCode === 201) {
            this.utilsSrv.showToastMessage(response.message, "success");
            this.router.navigate(['','roles']);
          }
        },
        (err) => {
          
          let { error } = err;

          if (this.utilsSrv.isObjectNotNullAndEmpty(error)) {

            if (error.message.includes("Duplicate entry")) {
              this.utilsSrv.showToastMessage("Role name exists", "error");
            } else {
              this.utilsSrv.showToastMessage(
                "Error occured while creating role",
                "error"
              );
            }
          } else {
            this.utilsSrv.showToastMessage(
              "Error occured while creating role",
              "error"
            );
          }
        }
      );
  }

  buildProfileData(response : any) {
    let { roleName, applicationId, description, applicationName } = response;
    
    this.profileData['firstName'] = roleName;
    this.profileData['selectedApplication'] = applicationId;
    this.profileData['applicationName'] = applicationName;
    this.profileData['description'] = description;

    console.log("profile Data ", this.profileData);
  }

  navigateToProfilesList() {
    this.router.navigate(['', 'roles']);
  }

}
