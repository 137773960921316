import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { USER_MGMT_CNST } from "src/app/constants";
import { HttpService } from "src/app/services/http.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {

  @Input() modalData;

  isFormNotValid: boolean = true;
  isNameTouched: boolean = false;
  actionColumns: any[];
  globalResourceCheck: boolean = true;

  mappedResourcesListToRole: any[] = [];
  resourcesListOfApplication: any[] = [];
  roleDetailsJson: any = {};

  scopeEndPoint: string;

  // Scope
  selectedScopeType: string;
  selectedScopeName: string[] = [];
  scopeTypes: string[] = [];
  scopeNames: string[] = [];

  scopeInfo: any[] = [];

  initScope: any[] = [];
  updatedRoleScope: any[] = [];

  selectedApplicationId: any;
  resourceList: any = [];
  initialMetaData : any = {};
  initialResourceActionIds: any = [];

  initialApplicationId : string = null;
  initialResourceActionIdsBackup : any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private envService : EnvService
  ) {}

  ngOnInit() {
    
    this.initialMetaData = {
      roleName: this.modalData.profilename,
      description: this.modalData.profiledesc,
      applicationId: this.modalData.applicationId,
      status: this.modalData.status === USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE ? true : false
    }

    //* Store the initial application id for which the resources are 
    //* loaded the first time when edit role button is clicked
    this.initialApplicationId = this.modalData.applicationId;

    this.roleDetailsJson = this.modalData.roleDetailsJson || { validScopes: [] };
    this.initScope = this.roleDetailsJson.validScopes || [];

    if (this.modalData.applicationId) {
      this.selectedApplicationId = this.modalData.applicationId;
      this.applicationChange();
    } else {
      this.selectedApplicationId = null;
    }
  }

  updateScope(data: any) {
    this.updatedRoleScope = data;
  }

  onFormSubmit(form: NgForm) {}
   
  // inputHasOnlyAlphabets(event: any) {
  //   const pattern = /^[ A-Za-z0-9_@./#()]*$/;
  //   let inputText = event.target.value;
  //   this.isFormNotValid =
  //     pattern.test(inputText) && inputText.length > 0 ? false : true;
  //   this.isNameTouched = true;
  // }

  checkValue(status : string) {

    if (status === USER_MGMT_CNST.STATUS_TYPE_LIST.INACTIVE) {
      this.modalData.status = USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE;
    } else {
      this.modalData.status = USER_MGMT_CNST.STATUS_TYPE_LIST.INACTIVE;
    }
  }
  onPaste(event:any){
    const pattern = /^[a-zA-Z0-9\s]+$/;
    let inputText = event
    this.isFormNotValid = pattern.test(inputText) ? false : true;
  }
  inputHasOnlyAlphabets(event: any) {
    let inputText = event.target.value;
    this.isNameTouched = true;
    if(inputText.trim().length > 0 ){
      this.onPaste(inputText)
    }else{
      this.isFormNotValid = inputText.length > 0 ? false : true;
    }
  }
  checkProfileName(){
    const pattern = /^[a-zA-Z0-9\s]+$/;
    return pattern.test(this.modalData.profilename) ? false : true;
  }
  onlyAlphabet(event: any) {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  space(event: any){
    if(event.target.selectionStart === 0 && event.code === 'Space'){
      event.preventDefault();
    }
  }
  applicationChange() {
    this.scopeEndPoint = '';

    let applicationId = this.selectedApplicationId;

    //* Store a backup of the resource action ids of the initial resource
    //* which was loaded so that when application is switched we can track
    //* and compare if some of these ids are to be moved to deleted array
    if (this.initialResourceActionIdsBackup.length === 0) {
      this.initialResourceActionIdsBackup = [...this.initialResourceActionIds];
    }

    const selectedApplicationInfo = this.modalData.applicationList.find(app => app.applicationId == this.selectedApplicationId);

    if(selectedApplicationInfo) {
      this.scopeEndPoint = selectedApplicationInfo.scopeEndPoint || '';
    }

    // TODO: Enable this for scope.
    // this.fetchScopeTypes();

    let options = {
      replaceParams: {
        // REPLACE_ROLE_ID: this.modalData.role_id,
        REPLACE_APP_ID: applicationId
      },
    };
    const roleOptions = {
      replaceParams: {
        REPLACE_ROLE_ID: this.modalData.role_id,
      }
    }

    this.resourceList = [];
    this.actionColumns = [];
    this.mappedResourcesListToRole = [];
    this.resourcesListOfApplication = [];

    this.httpSrv.makeGetApiCall('APP_RSC_LIST', this.envService.baseUrl,options)
    .subscribe(appResources => {
      this.resourcesListOfApplication = appResources && appResources.response ?  appResources.response : [];
      this.mappedResourcesListToRole = []

      this.httpSrv.makeGetApiCall("ROLE_RSC_LIST", this.envService.baseUrl, roleOptions)
      .subscribe(roleResource => {
        this.mappedResourcesListToRole = roleResource && roleResource.response ?  roleResource.response : [];
        this.formattingTableData(this.resourcesListOfApplication, this.mappedResourcesListToRole);
      }, err => {
        this.formattingTableData(this.resourcesListOfApplication, this.mappedResourcesListToRole);
      })
    })

    // forkJoin(
    //   this.httpSrv.makeGetApiCall('APP_RSC_LIST', this.envService.baseUrl,options).toPromise(),
    //   this.httpSrv.makeGetApiCall("ROLE_RSC_LIST", this.envService.baseUrl, roleOptions).toPromise()
    // ).subscribe(result => {
    //   this.resourcesListOfApplication = result[0] && result[0].response ? result[0].response : [];
    //   this.mappedResourcesListToRole = result[1] && result[1].response ? result[1].response : [];

    //   this.formattingTableData(this.resourcesListOfApplication, this.mappedResourcesListToRole);
    // }, err => console.log("Err:: ",err));
  }

  storeRsrcActionIds() {
    let list = [];

    //* Iterate over each resource for the application.For each resource
    //* push only the resource action id where the READ / EDIT action is enabled
    this.resourceList.forEach((item) => {
      this.actionColumns.forEach((action) => {
        if (
          item["actions"] &&
          item["actions"][action.action_name] &&
          item["actions"][action.action_name]["enabled"] === true
        ) {
          list.push(item["actions"][action.action_name]["resourceActionId"]);
        }
      });
    });
    return list;
  }

  updateCheck() {
    this.actionColumns.forEach((item) => {
      item["enabled"] = this.resourceList.every((a, _, [b]) => {
        return a["actions"][item.action_name]
          ? a["actions"][item.action_name]["enabled"]
          : true === b["actions"][item.action_name]
          ? b["actions"][item.action_name]["enabled"]
          : true;
      });
    });
    this.resourceList.forEach((item) => {
      item["enabled"] = this.actionColumns.some((a, _, [b]) => {
        // console.log("a:: ",a);
        // console.log("b:: ",b);
        return item["actions"][a.action_name]
          ? item["actions"][a.action_name]["enabled"]
          : false === item["actions"][b.action_name]
          ? item["actions"][b.action_name]["enabled"]
          : false;
      });
    });
    this.updateGlobalCheck();
  }

  getUniqueActions(list) {
    let formatted = [];
    list.forEach((element) => {
      element.actions.forEach((action) => {
        formatted.push({ action_name: action.action_name, enabled: true });
      });
    });
    this.actionColumns = formatted.filter(
      (v, i, a) => a.findIndex((t) => t.action_name === v.action_name) === i
    );
  }

  isResourceActionMappedToRole(mappedResourcesList, resourceId, actionName) {
    if(!actionName || !mappedResourcesList) return false;

    return mappedResourcesList.findIndex(resource => {
      if(resource.resourceId !== resourceId) {
        return false;
      }
      const actions = resource.actionsInfo;
      return actions.findIndex(a => a.actionName == actionName) != -1;
    }) !== -1;
  }

  formattingTableData(resourcesList, mappedResourcesList) {
    // console.log("list:: ",resourcesList);
    // console.log("Mapped resourcesList:: ", mappedResourcesList);
    
    const actionsMap = {};

    this.resourceList = resourcesList.map((element) => {
      // console.log("element:: ",element);
      let object = {};
      object["resource_name"] = element.resourceName;
      object['resource_type_name'] = element.resourceTypeName;
      object["enabled"] = true;
      object["actions"] = {};
      element.actionsInfo.forEach((action) => {
        action['enabled'] = this.isResourceActionMappedToRole(this.mappedResourcesListToRole, element.resourceId, action.actionName);
        object["actions"][action.actionName] = action;

        if(!actionsMap[action.actionName]) {
          actionsMap[action.actionName] = {
            action_name: action.actionName,
            enabled: true
          }
        }
      });

      return object;
    });
    this.actionColumns = Object.values(actionsMap);

    this.updateCheck();
    this.initialResourceActionIds = this.storeRsrcActionIds();
    console.log("Initial Resource Action ids ", this.initialResourceActionIds);
  }

  particularActionToogle(item) {
    this.resourceList.forEach((element) => {
      if (element["actions"][item.action_name]) {
        element["actions"][item.action_name]["enabled"] = item["enabled"];
        if(item.action_name === 'EDIT' && item.enabled) {
          element['actions']['READ'].enabled = item.enabled;
        }
      }
    });
    this.resourceList.forEach((element) => {
      element["enabled"] = this.actionColumns.some((a, _, [b]) => {
        return element["actions"][a.action_name]
          ? element["actions"][a.action_name]["enabled"]
          : false === element["actions"][b.action_name]
          ? element["actions"][b.action_name]["enabled"]
          : false;
      });
    });
    this.updateGlobalCheck();
  }

  checkAndUpdateCheckbox(item, index) {
    // console.log('Item:: ', item, ", Index:: ", index);
    if(item === 'EDIT') {
      console.log('resourceList:: ', this.resourceList);
      const isResourceActionTrue = this.resourceList[index] && this.resourceList[index].actions ? 
      this.resourceList[index].actions[item].enabled : false;

      // console.log("isResourceActionTrue: ", isResourceActionTrue);
      if(isResourceActionTrue && this.resourceList[index].actions['READ']) {
        // const actionsOfResource = Object.keys(this.resourceList[index].actions);
        this.resourceList[index].actions['READ'].enabled = true;
      }
    }
    this.specificActionToggle(item, index);
  }
  
  specificActionToggle(item, index) {
    let foundIndex = this.actionColumns.findIndex((x) => x.action_name == item);
    this.actionColumns[foundIndex]["enabled"] = this.resourceList.every(
      (a, _, [b]) => {
        return a["actions"][item]
          ? a["actions"][item]["enabled"]
          : true === b["actions"][item]
          ? b["actions"][item]["enabled"]
          : true;
      }
    );
    this.resourceList[index]["enabled"] = this.actionColumns.some(
      (a, _, [b]) => {
        return this.resourceList[index]["actions"][a.action_name]
          ? this.resourceList[index]["actions"][a.action_name]["enabled"]
          : false === this.resourceList[index]["actions"][b.action_name]
          ? this.resourceList[index]["actions"][b.action_name]["enabled"]
          : false;
      }
    );
    this.updateGlobalCheck();
  }

  allResourceToggle() {
    this.resourceList.forEach((element) => {
      element["enabled"] = this.globalResourceCheck;
      this.actionColumns.forEach((item) => {
        item["enabled"] = this.globalResourceCheck;
        if (element["actions"][item.action_name]) {
          element["actions"][item.action_name][
            "enabled"
          ] = this.globalResourceCheck;
        }
      });
    });
  }

  resourceToggle(i) {
    this.actionColumns.forEach((item) => {
      if (this.resourceList[i]["actions"][item.action_name]) {
        this.resourceList[i]["actions"][item.action_name][
          "enabled"
        ] = this.resourceList[i]["enabled"];
        item["enabled"] = this.resourceList.every((a, _, [b]) => {
          return a["actions"][item.action_name]
            ? a["actions"][item.action_name]["enabled"]
            : true === b["actions"][item.action_name]
            ? b["actions"][item.action_name]["enabled"]
            : true;
        });
      }
      this.updateGlobalCheck();
    });
  }

  updateGlobalCheck() {
    this.globalResourceCheck =
      this.resourceList.every((a, _, [b]) => a["enabled"] === b["enabled"]) &&
      this.resourceList && this.resourceList.length && this.resourceList[0]["enabled"]
        ? true
        : false;
  }

  fetchScopeTypes() {
    this.scopeTypes = [];
    this.scopeNames = [];
    this.selectedScopeType = "";
    this.selectedScopeName = [];
    if(!this.scopeEndPoint) {
      console.error("Scope End point not found");
      return;
    }
    this.httpSrv.makeGetApiCall('GET_SCOPE_INFO', this.scopeEndPoint)
    .subscribe(res => {
      console.log("Res:: ",res);
      this.scopeInfo = res;
    })
    // this.httpSrv.makeGetApiCall('GET_SCOPE_TYPES', this.scopeEndPoint)
    // .subscribe(res => {
    //   console.log("ScopeTypes:: ", res);
    //   this.scopeTypes = res || res.data || [];
    // }, err => this.scopeTypes = []);
  }

  onScopeTypeChange() {
    this.selectedScopeName = [];
    this.scopeNames = [];
    if(!this.scopeEndPoint) {
      console.error("Scope End point not found");
      return;
    }

    // console.log("Fetch scope names under: " + this.selectedScopeType);
    if(this.selectedScopeType) {
      const options = {
        replaceParams: {
          "REPLACE_SCOPE_TYPE": this.selectedScopeType
        }
      }
      this.httpSrv.makeGetApiCall('GET_SCOPE_NAMES', this.scopeEndPoint, options)
      .subscribe(res => {
        this.scopeNames = res.response || res.data || res || [];
        console.log("Names:: ",res);
      })
    }
  }

  update() {

    //? Enable this and the below if statement if you want the condition
    //? that atleast one resource should be selected for a role definition
    // let isAtleastOneResourceSelected = this.resourceList.some(item => item.enabled);

    // if (isAtleastOneResourceSelected) {
    let metaInfo = {
      roleName: this.modalData.profilename,
      description: this.modalData.profiledesc ? this.modalData.profiledesc : null,
      status: this.modalData.status === 'Active' ? true : false,
      applicationId: this.selectedApplicationId
    }

    // console.log("Backup resource action ids ", this.initialResourceActionIdsBackup);
    
    //* Get the resource action ids for those resources
    //* where atleast one action is selected
    let finalResult = this.storeRsrcActionIds();
    console.log("Final Result ", finalResult);

    let deletedIds = this.initialResourceActionIds.filter(
      (x) => !finalResult.includes(x) || this.selectedApplicationId != this.modalData.applicationId
    );

    console.log("Deleted Ids (1)", deletedIds);

    let newlyAddedIds = finalResult.filter(
      (x) => !this.initialResourceActionIds.includes(x)
    )

    //* Application has been changed in the dropdown
    //* Hence move the resources of initial application to 
    //* deleted resource action ids
    if (this.initialApplicationId !== this.selectedApplicationId) {
      console.log("Application changed ");

      deletedIds = [...deletedIds, ...this.initialResourceActionIdsBackup];
    } else {
      console.log("Application not changed");
    }

    let isMetaInfoChanged = this.getMetaInfoStr(this.initialMetaData) !== this.getMetaInfoStr(metaInfo) ? true : false;

    let metaInfoDetails = isMetaInfoChanged ? metaInfo : null;

    const details = {
      operation: USER_MGMT_CNST.OPERATION_TYPES.PROFILE.EDIT_PROFILE,
      postData: {
        role_id: this.modalData.role_id,
        addResourceActions: newlyAddedIds && newlyAddedIds.length > 0 ? newlyAddedIds : [],
        deleteResourceActions: deletedIds && deletedIds.length > 0 ? deletedIds : [],
        meta: metaInfoDetails,
        roleDetailsJson: {
          validScopes: (JSON.stringify(this.initScope) === JSON.stringify(this.updatedRoleScope) || !this.updatedRoleScope) ?
            this.initScope : this.updatedRoleScope
        }
      },
    };
    // console.log("Details ", details);
    this.activeModal.close(details);
    
    // } else {
    //   console.log("No resource selected for role definition");
    //   this.utilSrv.showToastMessage("Please select atleast one resource for role", "error");
    //   return;
    // }
  }

  getMetaInfoStr(userInfo: any) {
    if(!userInfo) return '';

    return JSON.stringify({
      applicationId: userInfo.applicationId,
      description: userInfo.description,
      roleName: userInfo.roleName,
      status: userInfo.status
    })
  }

}
