import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerCopyRightText : string = null;
  currentYear: number;
  constructor(private envService : EnvService,
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.footerCopyRightText = this.envService.copyRightText;
  }

}
