import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DATA_CNST } from 'src/app/constants/data.cnst'
import { UM_TABLE_CNST } from '../constants/table.cnst';
import { USER_MGMT_CNST } from '../constants';

import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { AppStateService } from './app-state.service';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { HttpService } from '../services/http.service';
import { UtilsService } from '../services/utils.service';
import { EnvService } from '../services/env.service';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  userPermissions: any = {};
  subscriptions : Subscription[] = [];

  constructor(
    private appStateSrv: AppStateService,
    private authSrv : AuthenticationService,
    private httpSrv : HttpService,
    private utilSrv : UtilsService,
    private envSrv : EnvService,
    private router : Router) { 

    const userPermissionsRef = this.appStateSrv.userPermissionsRef.subscribe((response: any) => {
      this.userPermissions = { ...response };
      userPermissionsRef.unsubscribe();
    })

    this.subscriptions.push(userPermissionsRef);
  }

  getUserData(){
    return {
      userId:"Harsh.Pensi@gapac.com" ,
      accessToken: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqYW5lZG9lIiwiaXNzIjoidmFzc2FyIiwiZXhwIjoxNjI5MzQwODU3LCJ0bXAiOjE2Mjg0NzY4NTcwNzR9.KDMOS2evjLJ7nVw3NTSDjr9RKRC3XB9rqX99oUFisJU",
    }
  }

  getListData(moduleName: string){
    let finaData : any = [];
     let data : {} = DATA_CNST.LIST_DATA[moduleName];
     for(let index=0; index<5 ; index++){
      finaData.push(data);
     }
     return finaData;
  }

  addOptionsToTableData(tableData: any[], resourceName?: string, tableName?: string) {
    let allowedOperations = this.getListOfOperationsToBeShownInTable(resourceName);

    let { LIST_NAME_MAPPING, CUSTOMER_ADMIN_ROLE, CUSTOMER_SERVICE_EXECUTIVE } = USER_MGMT_CNST;

    let userPermissions = this.appStateSrv.getUserPermissions();

    let isRoleTable = !isNullOrUndefined(tableName) &&
      (tableName === LIST_NAME_MAPPING.PROFILE_LIST ||
        tableName === LIST_NAME_MAPPING.PROFILE_CUSTOMER_LIST);

    //*If allowed operations does not contain read_self then exclude 
    //*currently logged in users data
    if (allowedOperations.indexOf(UM_TABLE_CNST.ES_OPTIONS.READ_SELF) === -1) {
      tableData = this.excludeUserIfNoReadSelf(tableData);
    }

    if (isRoleTable) {

      if (this.utilSrv.isObjectNotNullAndEmpty(userPermissions)) {
        let userMgmtPermissions = userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION];

        if (this.utilSrv.isObjectNotNullAndEmpty(userMgmtPermissions)) {
          let { roleName } = userMgmtPermissions;

          let isUserCustomerAdminOrCSE = roleName === CUSTOMER_ADMIN_ROLE || roleName === CUSTOMER_SERVICE_EXECUTIVE;

          if (!isUserCustomerAdminOrCSE) {

            return tableData.map(item => {

              let isCustomerSpecificRole = !isNullOrUndefined(item['customerName']);
              let modifiedOperations = [...allowedOperations];

              if (isCustomerSpecificRole) {
                modifiedOperations = allowedOperations.filter((action: string) => action !== 'Edit');  
              }else {
                item['customerName'] = 'SuperAdmin';
              }

              return { ...item, 'options': [...modifiedOperations], 'status': item.status ? USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE : USER_MGMT_CNST.STATUS_TYPE_LIST.INACTIVE }
            })
          } else {
            return this.getModifiedTableDataWithActions(tableData, allowedOperations);
          }
        }
      }
    } else {
      return this.getModifiedTableDataWithActions(tableData, allowedOperations);
    } 
  }

  getModifiedTableDataWithActions(tableData : any[], operations : string[]) {
    return tableData.map(item => {
      return { ...item, 'options': [...operations], 'status': item.status ? USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE : USER_MGMT_CNST.STATUS_TYPE_LIST.INACTIVE }
    })
  }

  getAllowedOperationsForResource(resourceName: string) {
    this.userPermissions = this.appStateSrv.getUserPermissions();
    
    // TODO: should be loaded based on  user permissions.
    if (Object.keys(this.userPermissions).length > 0) {
      return this.userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION]["resources"][resourceName] 
      ?  this.userPermissions[USER_MGMT_CNST.CURRENT_APPLICATION]["resources"][resourceName].actions
      : [];
    }
    // return [UM_TABLE_CNST.ES_OPTIONS.READ, UM_TABLE_CNST.ES_OPTIONS.EDIT];
  }

  getListOfOperationsToBeShownInTable(resourceName: string) : string[] {
    let possibleOperations = Object.keys(UM_TABLE_CNST.ES_OPTIONS);

    let allowedOperationsForResource = this.getAllowedOperationsForResource(resourceName);

    if (!isNullOrUndefined(allowedOperationsForResource)) {
      possibleOperations = possibleOperations.filter(operation => allowedOperationsForResource.indexOf(operation) !== -1);

      possibleOperations = possibleOperations.map(operation => {
        let operations = operation.split("_");
        let finalOperation = [];

        operations.forEach((op: string) => {
          op = op.substring(0, 1) + op.substring(1).toLowerCase();
          finalOperation.push(op);
        })

        return finalOperation.join("_");
      });
    }

    return possibleOperations;
  }

  excludeUserIfNoReadSelf(tableData : any[]) {

    let email = null;

    if(USER_MGMT_CNST.ACCESS_TYPE === 'prod') {
      let userInfo = sessionStorage.getItem('id_token_claims_obj');
      email = userInfo.sub;
    }
    else {
      let { userId } = this.getUserData();
      email = userId;
    }

    if(!isNullOrUndefined(email)) {
      tableData = tableData.filter(item => item.email_id !== email);
    }

    return tableData;
  }

  setLoggedInUserInfo(loginResponse: any) {
    let { statusCode, response } = loginResponse;

    if (statusCode === 200) {

      if (!isNullOrUndefined(response)) {
        let { userDetails, promptForChangePassword = false, token, tokens } = response;
        let { csrf  } = tokens;

        if (promptForChangePassword) promptForChangePassword = false;

        const { firstName, lastName, username, userId } = userDetails || {
          firstName: '',
          lastName: '',
          username: '',
          userId: '',
        };

        this.authSrv.setUserInfo({ firstName, lastName, username, userId ,promptForChangePassword}, token);

        this.appStateSrv.setPermissionsLoaded(false);
        this.appStateSrv.setInProcess(false);
        this.appStateSrv.setUserMetaInfo({});
        this.appStateSrv.setUserPermissionsRef({});

        setTimeout(() => {
          const isPromptChangePswd = this.authSrv.isPromptToChangePswd();
          
          if(isPromptChangePswd){
            this.router.navigate(['', 'authentication', 'change-password'])
            return;
          }
          // this.router.navigate(['', 'users']);
        }, 0);
      }
    }
  }


  sortConfigFields(configInfo : any, key: string) {
    let configArray : any = [];
    let configCopy = {...configInfo};

    for (let key in configCopy) {
      let configObj = {};
      let configValue = configCopy[key];

      configObj[key] = configValue;
      configArray.push(configObj);
    }
    
    //Sort config fields as per desired order
    configArray.sort((config1 : any, config2 : any) => {
      return config1['sortIndex'] < config2['sortIndex'];
    });

   return configArray;
  }

  rearrangeConfigFields(sortedConfig : any[], key : string) {
    let finalConfigInfo = {};
    let allConfigItems = {};

    let configCopy = JSON.parse(JSON.stringify(sortedConfig));

    configCopy.forEach((configItem : any) => {
      let key = Object.keys(configItem)[0];
      let value = configItem[key];
      allConfigItems[key] = value;
    })

    finalConfigInfo[key] = allConfigItems;

    return finalConfigInfo;
  }

  formatCustomerNameSearchResponse(response: any) : any[] {
    let formattedResponse = [];

    let customerIds = Object.keys(response);

    customerIds.forEach((customerId : string) => {
      let { customerName } = response[customerId];
      formattedResponse.push({customerId, customerName});
    })

    return formattedResponse;
  }

  filterCustomerTableRecordsForCustomerName(searchResults: any[], customerIds : string[]): any[] {
    return searchResults.filter(result => customerIds.indexOf(result.customerId) !== -1);
  }

  buildCustomerAttributeValues(customerAttributes : any) : any[] {

    let finalAttributes = [];

    for (let key in customerAttributes) {
      let value = customerAttributes[key];

      let config = {
        label : key,
        values : isNullOrUndefined(value) ? '' : [value]
      }

      finalAttributes.push(config);
    }
    return finalAttributes;
  }

  checkIfAllAttributesAreFilled(attributeConfig : any[]) : boolean {
    let isFilled = attributeConfig.every((attribute : any) => attribute.values !== '');
    return isFilled;
  }

  getRoleAndRedirect() {
    let allUserPermissions = this.appStateSrv.getUserPermissions();
    console.log("All user permissions ", allUserPermissions);

    //* If permissions exist and it is not null and empty
    if (this.utilSrv.isObjectNotNullAndEmpty(allUserPermissions)) {

      //TODO : Considering only first application assigned to user
      //TODO : Generalize when user has been assigned multiple applications
      // let appName = Object.keys(allUserPermissions)[0];
      let appName = USER_MGMT_CNST.CURRENT_APPLICATION;

      if (!isNullOrUndefined(appName)) {  

        //* Get the permissions of the user with respect to application name
        let appPermissions = allUserPermissions[appName];

        if (this.utilSrv.isObjectNotNullAndEmpty(appPermissions)) {

          //* Get role assigned to the user in the application
          let { roleName = null } = appPermissions;

          if (!isNullOrUndefined(roleName)) {

            let isUserSuperAdminOrCustAdminOrCSE = (roleName === USER_MGMT_CNST.CUSTOMER_ADMIN_ROLE
               || roleName ===  USER_MGMT_CNST.SUPER_ADMIN
               || roleName === USER_MGMT_CNST.CUSTOMER_SERVICE_EXECUTIVE) ? true : false;

            console.log("is user mgmt role ", isUserSuperAdminOrCustAdminOrCSE)

            let redirectUrl = isUserSuperAdminOrCustAdminOrCSE
              ? this.envSrv.userMgmtRedirectUrl
              : this.envSrv.appRedirectUrl;

            this.navigateBasedOnRedirectUrl(redirectUrl);
          }
        } else {
          let redirectUrl = this.envSrv.appRedirectUrl;
          this.navigateBasedOnRedirectUrl(redirectUrl);
        }
      }
    }
  }

  navigateBasedOnRedirectUrl(redirectUrl: string) {

    let currentRouteUrl = this.router.url;

    let isAlreadyOnSameRoute = `/${redirectUrl}` === currentRouteUrl ? true : false;
    let isRouteForChangePassword = window.location.href.includes('change-password');

    console.log("Is route for change-password ", isRouteForChangePassword);

    if (!isNullOrUndefined(redirectUrl) && !isAlreadyOnSameRoute && !isRouteForChangePassword) {
      this.router.navigate([redirectUrl, { externalUrl: redirectUrl }]);
    }
  }

  areEditCustomerFormValuesModified(originalData : any, modifiedData : any) : boolean {
    let originalDataKeys = Object.keys(originalData);
    let modifiedDataKeys = Object.keys(modifiedData);

    let isModified = false;

    for (let i = 0; i < modifiedDataKeys.length; i++) {

      let modifiedValue = modifiedData[modifiedDataKeys[i]];
      let originalValue = originalData[originalDataKeys[i]];

      if (originalValue !== modifiedValue) {
        isModified = true;
        break;
      }
    }
    
    return isModified;
  }

  getCustomerInfoByCustomerId(options: any): Promise<any> {

    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('FETCH_CUSTOMER_ATTRIBUTES', this.envSrv.baseUrl, options)
        .subscribe((customerAttributes: any) => {
          resolve(customerAttributes);
        }, err => {
          console.log("Error in fetching customer attributes ", err);
          this.utilSrv.showToastMessage("Error in fetching customer attributes", "error");
          resolve({});
        })
    })
    return promise;
  }

  getCustomerAdminInfoByCustomerId(options: any): Promise<any> {

    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('FETCH_CUSTOMER_ADMIN_INFO_BY_CUSTOMER_ID', this.envSrv.baseUrl, options)
        .subscribe((customerAdminInfo: any) => {
          resolve(customerAdminInfo);
        }, err => {
          console.log("Error in fetching customer admin info by customer id ", err);
          this.utilSrv.showToastMessage("Error in fetching customer admin info", "error");
          resolve({});
        })
    })
    return promise;
  }

  buildCustomerInfoPostData(response : any) {
    let { customerName, email, primaryContact, secondaryContact, address, billingAddress } = response;
    return { customerName, email, primaryContact, secondaryContact, address, billingAddress };
  }

  getRoleInfoById(options: any): Promise<any> {
    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('GET_ROLE_INFO_BY_ID', this.envSrv.baseUrl, options)
        .subscribe((roleInfo: any) => {
          resolve(roleInfo);
        }, err => {
          console.log("Error in fetching role info by role id ", err);
          this.utilSrv.showToastMessage("Error in fetching role info", "error");
          resolve({});
        })
    })
    return promise;
  }

  fetchAllResourcesForAllAssignedAppsByCustomerId(options: any): Promise<any> {
    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('FETCH_ALL_ASSIGNED_RESOURCES_FOR_ASSIGNED_APPLICATIONS', this.envSrv.baseUrl, options)
        .subscribe((applicationListResponse: any) => {
          resolve(applicationListResponse);
        }, err => {
          console.log("Error in fetching assigned applications for customer", err);
          this.utilSrv.showToastMessage("Error in fetching assigned applications for customer", "error");
          resolve({});
        })
    })
    return promise;
  }
}
