import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { isEmptyArray } from 'src/app/modules/shared/common-utils';
import { HttpService, UtilsService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-target-treatment',
  templateUrl: './target-treatment.component.html',
  styleUrls: ['./target-treatment.component.scss']
})
export class TargetTreatmentComponent {
  activespiner:boolean = false;
  nameAlreadyExits:boolean = false;
  filteredItems:any[]=[];
  items1:any[] = [
    // {
    //   customerName: 'Customer1',
    //   targetAttributes: [
    //     {
    //       name: 'Target Attribute Group 01',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions','Transactional'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Target Attribute Group 02',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 03',
    //       attributesList: ['Location','Demo Graphics','Promotions','Transactional'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 04',
    //       attributesList: ['Location','Demo Graphics','Interests','Transactional'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   treatmentGroups: [
    //     {
    //       name: 'Treatment Group 01',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Treatment Group 02',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Roaming Charges'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Treatment Group 03',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans'],
    //       isAccordionOpen: false
    //     },{
    //       name: 'Treatment Group 04',
    //       attributesList: ['Coverage Area','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   isAccordionOpen: true
    // },
    // {
    //   customerName: 'Customer2',
    //   targetAttributes: [
    //     {
    //       name: 'Target Attribute Group 01',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions','Transactional'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Target Attribute Group 02',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 03',
    //       attributesList: ['Location','Demo Graphics','Promotions','Transactional'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 04',
    //       attributesList: ['Location','Demo Graphics','Interests','Transactional'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   treatmentGroups: [
    //     {
    //       name: 'Treatment Group 01',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Treatment Group 02',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Roaming Charges'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Treatment Group 03',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans'],
    //       isAccordionOpen: false
    //     },{
    //       name: 'Treatment Group 04',
    //       attributesList: ['Coverage Area','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   isAccordionOpen: false
    // },
    // {
    //   customerName: 'Customer3',
    //   targetAttributes: [
    //     {
    //       name: 'Target Attribute Group 01',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions','Transactional'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Target Attribute Group 02',
    //       attributesList: ['Location','Demo Graphics','Interests','Promotions'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 03',
    //       attributesList: ['Location','Demo Graphics','Promotions','Transactional'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Target Attribute Group 04',
    //       attributesList: ['Location','Demo Graphics','Interests','Transactional'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   treatmentGroups: [
    //     {
    //       name: 'Treatment Group 01',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: true
    //     },
    //     {
    //       name: 'Treatment Group 02',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Roaming Charges'],
    //       isAccordionOpen: false
    //     },
    //     {
    //       name: 'Treatment Group 03',
    //       attributesList: ['Coverage Area','Network Speed','5G Technology','Data Plans'],
    //       isAccordionOpen: false
    //     },{
    //       name: 'Treatment Group 04',
    //       attributesList: ['Coverage Area','5G Technology','Data Plans','Roaming Charges'],
    //       isAccordionOpen: false
    //     }
    //   ],
    //   isAccordionOpen: false
    // }
  ]
  attributeName: any = '';
  treatmentName: any = '';
  newTargetAttribute: any = '';
  newTreatmentAttribute: any = '';
  index: any;

  constructor (private modalService: NgbModal,
    private httpSrv: HttpService,
    private envService : EnvService,
    private utilSrv: UtilsService
    ) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  openModal(targetModal: any,index: any){
    this.index = index;
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
  });
  }

  errorMessage: any = {
    show:false,
    message:'',
    target:false,
    treatment:false
  }

  onAddAttributeTarget(index:any, index1:any){
    if (this.attributeName.trim() !== '' && !this.errorMessage.target) {
      const existingAttributes = this.items1[index]['targetAttributes'][index1]['attributesList'];
      if (existingAttributes.includes(this.attributeName)) {
        this.errorMessage['message'] = 'Attribute already exists!';
        this.errorMessage['target'] = true;
        this.errorMessage['treatment'] = false;
        return;
      }else{
        this.items1[index]['targetAttributes'][index1]['attributesList'].push(this.attributeName.trim());
        this.errorMessage['target'] = false;
        this.errorMessage['treatment'] = false;
        this.errorMessage.message = '';
      }
    this.attributeName = '';
    }
  }
  onTargetSearch(index:any,index1:any){
    if (this.attributeName.trim() !== '') {
      const existingAttributes = this.items1[index]['targetAttributes'][index1]['attributesList'];
      // Convert existing attribute names to lowercase
      const existingAttributesLowercase = existingAttributes.map(attr => attr.trim().toLowerCase());
      if (existingAttributesLowercase.includes(this.attributeName.trim().toLowerCase())) {
        this.errorMessage['message'] = 'Attribute already exists!';
        this.errorMessage['target'] = true;
        this.errorMessage['treatment'] = false;
        return;
      }
      this.errorMessage.message = '';
      this.errorMessage['target'] = false;
      this.errorMessage['treatment'] = false;
    }else{
      this.errorMessage.message = '';
      this.errorMessage['target'] = false;
      this.errorMessage['treatment'] = false;
    }
  }
  onTreatMentSearch(index:any,index1:any){
    if (this.treatmentName.trim() !== '') {
      const existingAttributes = this.items1[index]['treatmentGroups'][index1]['attributesList'];
      const existingAttributesLowercase = existingAttributes.map(attr => attr.trim().toLowerCase());
      if (existingAttributesLowercase.includes(this.treatmentName.trim().toLowerCase())) {
        this.errorMessage['message'] = 'Attribute already exists!';
        this.errorMessage['treatment'] = true;
        this.errorMessage['target'] = false;
        return;
      }
      this.errorMessage['treatment'] = false;
      this.errorMessage['target'] = false;
      this.errorMessage.message = '';
    }else{
      this.errorMessage['treatment'] = false;
      this.errorMessage['target'] = false;
      this.errorMessage.message = '';
    }
  }

  onAddAttributeTreatment(index: any, index1: any){
    if (this.treatmentName.trim() !== '' && !this.errorMessage.treatment) {
      const existingAttributes = this.items1[index]['treatmentGroups'][index1]['attributesList'];
      if (existingAttributes.includes(this.treatmentName)) {
        this.errorMessage['message'] = 'Attribute already exists!';
        this.errorMessage['treatment'] = true;
        this.errorMessage['target'] = false;
        return;
      }else{
        this.items1[index]['treatmentGroups'][index1]['attributesList'].push(this.treatmentName.trim());
        this.errorMessage['treatment'] = false;
        this.errorMessage['target'] = false;
        this.errorMessage.message = '';
      }
    this.treatmentName = '';
    }
    // this.treatmentName != '' ? this.items1[index]['treatmentGroups'][index1]['attributesList'].push(this.treatmentName) : '';
    // this.treatmentName = '';
  }
  checkTargetAttribute(type:any) {
    for (const customer of [this.items1[this.index]]) {
        for (const target of customer[type]) {
            if (target.name.toLowerCase() === (type == 'treatmentGroups' ? this.newTreatmentAttribute.trim().toLowerCase() : this.newTargetAttribute.trim().toLowerCase())) {
                this.nameAlreadyExits = true;
                break;
            }else{
              this.nameAlreadyExits = false;
            }
        }
        if (this.nameAlreadyExits) {
            break;
        }
    }
}
  onTargetAttributeAdd() {
    let obj:any = {
      name: this.newTargetAttribute.trim(),
      attributesList: [],
      isAccordionOpen: true
    }
    this.items1[this.index]['targetAttributes'].push(obj);
    this.newTargetAttribute = '';
    this.modalService.dismissAll();
  }

  onTreatmentGroupAdd() {
    let obj:any = {
      name: this.newTreatmentAttribute.trim(),
      attributesList: [],
      isAccordionOpen: true
    }
    this.items1[this.index]['treatmentGroups'].push(obj);
    this.newTreatmentAttribute = '';
    this.modalService.dismissAll();
  }

  onRemoveTreatmentGroup(index: any, index1:any){
    this.items1[index]['treatmentGroups'].splice(index1, 1);
  }

  onRemoveTargetAttribute(index: any, index1:any){
    this.items1[index]['targetAttributes'].splice(index1, 1);
  }
 
  getCustomers(){
    this.activespiner = true;
      this.httpSrv
        .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
        .subscribe((response: any) => {
          this.items1 = [];
        let data:any[] = Object.values(response);
        this.items1 = data;
        this.items1 = this.items1.map(item => ({
          customerId: item.customerId,
          customerName: item.customerName,
          isAccordionOpen: false,
          targetAttributes:[],
          treatmentGroups:[]
      }));
      this.items1.map((data:any,index:any)=>{
        if(index == 0){
          this.items1[0]['isAccordionOpen']=true
        }
        this.filteredItems = this.items1;
        this.getTreatmentGroupsByCustomerId(data.customerId,index);
        this.getTargetGroupByCustmer(data.customerId);
      })
      this.activespiner = false;
        }, error => {
          // this.utilSrv.showToastMessage("Failed to fetch customers list", "error");
          this.activespiner = false;
          this.items1 = [];
          this.items1 = Object.values({
            "40bc3d14-3279-413f-a1ec-bd81e5eb6d97": {
                "customerId": "40bc3d14-3279-413f-a1ec-bd81e5eb6d97",
                "customerName": "mahipaltest",
                "customerAdminEmail": "megavathmahipal766@gmail.com",
                "customerAdminName": "mahipal test",
                "addedDate": "2024-01-23T13:34:03.000+00:00",
                "address": null,
                "billingAddress": null,
                "primaryContact": "+918978690806",
                "secondaryContact": null,
                "resourceCount": null,
                "email": "mahipaltest@gmail.com"
            },
            "4ca092c9-4af8-42b8-8d32-074b0c891faf": {
                "customerId": "4ca092c9-4af8-42b8-8d32-074b0c891faf",
                "customerName": "Tmobile",
                "customerAdminEmail": "vikram@gmail.com",
                "customerAdminName": "vikram tiwari",
                "addedDate": "2022-10-11T08:45:17.000+00:00",
                "address": null,
                "billingAddress": null,
                "primaryContact": "+919059360678",
                "secondaryContact": null,
                "resourceCount": {
                    "Widgets": 2,
                    "APIs": 13,
                    "Dashboards": 1
                },
                "email": "vikram@gmail.com"
            }});
          this.items1 = this.items1.map(item => ({
            customerId: item.customerId,
            customerName: item.customerName,
            isAccordionOpen: false,
            targetAttributes:[],
            treatmentGroups:[]
        }));
        this.items1.map((data:any,index:any)=>{
          if(index == 0){
            this.items1[0]['isAccordionOpen']=true
          }
          this.getTreatmentGroupsByCustomerId(data.customerId,index)
          this.getTargetGroupByCustmer(data.customerId)
        })
        })
  }
  onSave(data:any,i:any){
    if(data.targetAttributes.length == 0 || data.treatmentGroups.length == 0){
      this.utilSrv.showToastMessage(data.targetAttributes.length == 0 ? 'Target Should Not be Empty':'Treatment Groups Should Not be Empty', "error");
      return;
    }
    if (data.targetAttributes.length !=0 && data.targetAttributes.some((x: any) => isEmptyArray(x.attributesList))) {
      this.utilSrv.showToastMessage("Fill the Target Attributes", "error");
      return;
    }
    if (data.treatmentGroups.length != 0 && data.treatmentGroups.some((x: any) => isEmptyArray(x.attributesList))) {
      this.utilSrv.showToastMessage("Fill the Treatment Groups Attributes", "error");
      return;
    }
    this.activespiner = true;
    const transformedData: { [key: string]: string[] } = {};
    data.treatmentGroups.forEach(obj => {
        transformedData[obj.name] = obj.attributesList;
    });
    let postData = {"customerId" : data.customerId,
    "customerName":data.customerName,
    "treatMentConfiguration":transformedData}
    this.httpSrv
      .makePostApiCall('SAVE_TARGET_TREATMENT_CONFIGS',postData, this.envService.kredosUrl)
      .subscribe((response: any) => {
        this.activespiner = false;
        this.utilSrv.showToastMessage("Sucessfully Saved", "success");
      }, error => {
        this.utilSrv.showToastMessage("Failed to save", "error");
        this.activespiner = false;
      })
    this.saveTargetConfigs(data);
  }
  getTreatmentGroupsByCustomerId(customerId:any,index:any){
    let options = {
      replaceParams: {
        REPLACE_CUSTOMER_ID: customerId
      },
    };
    this.activespiner = true;
    this.httpSrv
      .makeGetApiCall('GET_TREATMENT_GROUPS', this.envService.kredosUrl,options)
      .subscribe((response: any) => {
        if(response.response != null){
        let index = this.items1.findIndex((data: any) => {
          return data.customerId == customerId;
      });
        if(index != -1){
          const transformedData = Object.keys(response.response.treatMentConfiguration).map(key => ({
            name: key,
            attributesList: response.response.treatMentConfiguration[key],
        }));
          transformedData[0]['isAccordionOpen']=true
          this.items1[index].treatmentGroups = transformedData
        }}
        this.activespiner = false;
      }, error => {
        // this.utilSrv.showToastMessage("Failed to save", "error");
        this.activespiner = false;
      })
  }
  transformTarget(original: any[]): any[] {
    return original.map(item => {
      return {
          parent: "Sigma",
          key: item.name,
          values: item['attributesList'],
          type: "multi-select-checkbox",
          range: null
      };
  });
  }
  saveTargetConfigs(data:any){
    const originalData: any = {};
    data.targetAttributes.forEach(obj => {
      originalData[obj.name] = obj.attributesList;
    });
    const transformedData: any[] = this.transformTarget(data.targetAttributes);
    this.activespiner = true;
    let postData = {
    "customerName":data.customerName,
    "targetConfiguration":transformedData}
    this.httpSrv
      .makePostApiCall('SAVE_TARGET_CONIFGS',postData, this.envService.kredosUrl)
      .subscribe((response: any) => {
        this.activespiner = false;
      }, error => {
        this.utilSrv.showToastMessage("Failed to save", "error");
        this.activespiner = false;
      })
  }

  getTargetGroupByCustmer(customerId:any){
    let options = {
      replaceParams: {
        REPLACE_CUSTOMER_ID: customerId
      },
    };
    this.activespiner = true;
    this.httpSrv
      .makeGetApiCall('GET_TARGET_GROUPS', this.envService.kredosUrl,options)
      .subscribe((response: any) => {
        if(response.response != null){
        response = JSON.parse(response.response);
          let index = this.items1.findIndex((data: any) => {
            return data.customerId == customerId;
        });
          if(index != -1){
            const transformedData = this.convertBackToOrginalTarget(response)
            transformedData[0]['isAccordionOpen']=true
            this.items1[index].targetAttributes = transformedData
          }}
        this.activespiner = false;
      }, error => {
        // this.utilSrv.showToastMessage("Failed to save", "error");
        this.activespiner = false;
      })
  }
  convertBackToOrginalTarget(originalData: any[]): any[] {
    return originalData.map(item => ({
        name: item.key,
        attributesList: item.values,
    }));
  }
  onRemoveTargetList(i: number, j: number,k:any) {
    const item = this.items1[i].targetAttributes[j];
    item.attributesList.splice(k, 1);
  }
  onRemoveattributesList(i: number, j: number,k:any) {
    const item = this.items1[i].treatmentGroups[j];
    item.attributesList.splice(k, 1);
  }
  clearInput() {
    this.newTreatmentAttribute = '';
    this.newTargetAttribute = '';
    this.nameAlreadyExits = false;
  }
  onModalDismiss(modal: any) {
    modal.dismiss('Cross click');
    this.clearInput();
  }
  ngOnDestroy() {
    this.clearInput();
    this.errorMessage = {
      show:false,
      message:'',
      target:false,
      treatment:false
    }
  }
  filterItemsByCustomerName(searchTerm: string) {
    this.items1 = this.filteredItems.filter(item =>
      item.customerName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
