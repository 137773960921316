import { isNullOrUndefined } from 'util';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { forkJoin, Observable, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  @Input() modalData;
  @Input() editUserConfig;

  emailPattern = USER_MGMT_CNST.EMAIL_PATTERN;

  customerName: string = null;
  customerId : string = null;
  userName : string = null;
  mobileNumber : string = null;

  userForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', []),
    // username: new FormControl('', [Validators.required]),
    status: new FormControl(true, [Validators.required]),
    email: new FormControl('', [Validators.required,Validators.pattern(this.emailPattern)]),
    // mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[9876][0-9]{9}$/)]),
    newRole: new FormControl('', []),
    customer : new FormControl('', []),
    scope: new FormArray([])
  })

  userDbSnapshot: any = {};
  applications: any[] = [];

  addedRoles : any = 1;
  selectedProfiles : any = [];
  selectedCustomers : any = [];
  selected= [];

  customer :any;

  roles : any[] = [];
  filteredRoles: any[] = [];
  selectedApplications: any = {};
  filterOptions: any = {}; // key: filterName, value: options.

  appIdMap: {[key: string]: any} = {}; // Application: ScopeEndPoint 

  currentRoleName : string = null;
  currentApplicationName : string = null;
  roleRemoved : boolean = false;
  isEmailValid : boolean = true;
  scopeVisible:boolean = false;
  userRoles:any[]=[];
  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private dataSrv : DataService,
    private utilSrv: UtilsService
  ) { }
  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilSrv.isEmailValid(this.emailPattern, inputStr);
  }
  ngOnInit() {

    //TODO:
    /**
     *  
     */

    // console.log("modalData:; ", this.modalData);

    let configLabelName = USER_MGMT_CNST.CONFIG_LABELS.EDIT_USER;
    let sortedConfigFields = this.dataSrv.sortConfigFields(this.editUserConfig.editUser, configLabelName);
    let reArrangedConfigFields = this.dataSrv.rearrangeConfigFields(sortedConfigFields, configLabelName);
    // console.log(reArrangedConfigFields);
    
    // TODO: Load Profiles
    this.loadData();

    let { applicationDetails } = this.modalData;
    let { roleName, applicationName } = applicationDetails;

    this.currentRoleName = roleName;
    this.currentApplicationName = applicationName;

    // TODO: Prepopulate user data.
    // this.prepopulateUserData(this.modalData);
  }

  loadData(){
    this.loadRoles();
  }

  loadRoles() {
    this.filteredRoles = [];
    let userId =  this.modalData.userId;
    let options = {
      uriParams:{
        userId : userId
      }
    }
    // Load Roles
    this.httpSrv.makeGetApiCall('LIST_OF_PROFILES_FOR_USER', this.envService.baseUrl,options)
    .subscribe(roles => {
      this.roles = roles['response'] || [];
      this.loadScopeEndPoints();
      this.filteredRoles = this.roles.map(r => {
        if(r.roleDetailsJson && r.roleDetailsJson.validScopes) {
          r.roleDetailsJson.validScopes = r.roleDetailsJson.validScopes.map(s => (
            {...s, valuesLoaded: false, scopeValue: this.applications}
          ))
        }
        return r;
      });
    }, err => this.roles = []);
  }

  loadScopeEndPoints() {
    this.appIdMap = {};
    this.httpSrv.makeGetApiCall("GET_APPLICATIONS_LIST", this.envService.baseUrl)
    .subscribe(apps => {
      apps.forEach(app => {
        this.appIdMap[app.applicationId] = app;
      })
      // console.log("Scope EndPoints updated:: ", this.appIdMap);
      this.prepopulateUserData(this.modalData);
    }, err => console.log("Err:: ", err));
  }

  /**================== Scope Changes ======================== */
  get scopeArray(): FormArray {
    return this.userForm.get("scope") as FormArray;
  }

  getFiltersOfRole(index: number): any[] {
    const roleId = this.getRoleIdFromScopeArray(index);
    // console.log("RoleId:: ", roleId);

    if(!roleId) return [];

    return this.getValidScopeOfRole(roleId);
  }

  newRoleFormGroup(roleId: string, filters: any[]): FormGroup {
    // Build formControl obj.
    let formControls = {
      roleId: new FormControl(roleId, []),
    }

    // Build formControls for filters(scope) and push it to formControl Obj..
    filters.forEach(f => {
      if(f.scopeName) {
        // Filters are not required
        // TODO: Replace scope name with scopeId()
        formControls[f.scopeName] = new FormControl([], []);
      }
    })

    // Build formgroup with roleId and filters and return it.
    return new FormGroup(formControls);
  }

  addRole(roleId: string = '') {
    // fetch the new role
    const newRoleId = roleId || this.userForm.get('newRole').value;
    
    if(!newRoleId) return;

    // Get role info
    const roleInfo = this.getRoleInfoById(newRoleId);

    if(roleInfo && roleInfo.applicationId) {
      this.roleRemoved = false;
      this.selectedApplications[roleInfo.applicationId] = true;
    }

    // Get the filters for role.
    const filters = this.getValidScopeOfRole(newRoleId);

    // Find the list of filters for which we need to load the values. 
    const loadValuesForFilters = filters.filter(f => {
      const filterId = this.getScopeId(f);
      return !this.filterOptions[filterId];
    });

    this.loadFilterOptions(newRoleId, loadValuesForFilters);

    this.scopeArray.push(this.newRoleFormGroup(newRoleId, filters));

    // Filter the roles dropdown.
    this.filterRoles();

    // reset the new role value.
    this.userForm.get('newRole').reset();
  }

  removeScope(index: number) {
    const roleId = this.getRoleIdFromScopeArray(index);
    const roleInfo = this.getRoleInfoById(roleId);

    if (!isNullOrUndefined(roleInfo)) {
      this.roleRemoved = false;
      if (this.selectedApplications[roleInfo.applicationId]) {
        delete this.selectedApplications[roleInfo.applicationId];
      }
    } else {
      this.roleRemoved = true;
    }

    this.scopeArray.removeAt(index);
    this.filterRoles();
  }

  filterRoles() {
    // Filter the roles dropdown.
    this.filteredRoles = this.roles.filter(r => !this.selectedApplications[r.applicationId]);
  }

  getApplicationScopeEndPoint(appId: string) {
    if(!appId) return null;
    return this.appIdMap[appId] ? this.appIdMap[appId].scopeEndPoint : null;
  }

  getApplicationIdOfRole(roleId: string) {
    if(!roleId) return null;

    const roleInfo = this.roles.find(r => r.roleId === roleId);
    return roleInfo ? roleInfo.applicationId : null;
  }

  getScopeId(scope: any) {
    if(!scope) return null;

    return `${scope.scopeType}__${scope.scopeName}`;
  }

  getRoleIdFromScopeArray(index: number) {
    if(isNullOrUndefined(index)) return null;

    if(!this.scopeArray.controls || !this.scopeArray.controls[index]) return null;
    
    return this.scopeArray.controls[index].get('roleId').value;
  }

  getRoleName(index: number, roleName : string): string {
    const roleId = this.getRoleIdFromScopeArray(index);
    if(!roleId) return '';
    const roleInfo = this.getRoleInfoById(roleId);
    if(isNullOrUndefined(roleInfo)){
      let roleInfo1:any = this.userRoles.find(r => r.roleId === roleId);
      return roleInfo1 ? roleInfo1.roleName+' ('+roleInfo1.applicationName+")" : this.currentRoleName +' ('+this.currentApplicationName+")"
    }else{
      return roleInfo ? roleInfo.roleName+' ('+roleInfo.applicationName+")" : this.currentRoleName +' ('+this.currentApplicationName+")"
    }
  }

  getRoleInfoById(roleId: string) {
    return this.roles.find(r => r.roleId === roleId);
  }

  getValidScopeOfRole(roleId: string): any[] {
    const roleInfo = this.getRoleInfoById(roleId);
    if(!roleInfo) return [];

    if(!roleInfo.roleDetailsJson || !roleInfo.roleDetailsJson.validScopes) return [];

    return roleInfo.roleDetailsJson.validScopes;
  }

  // TODO: replace with api call.
  fetchScopeValuesForFilter(roleId: string, scope: any): Observable<any> {
    // console.log("[fetchScopeValuesForFilter]:: roleId: ", roleId, ", Scope:: ", scope);
    if(!roleId || !scope) return of([]);

    // fetch application id of role.
    const applicationIdOfRole = this.getApplicationIdOfRole(roleId);
    // console.log("Application Id Of role: ", applicationIdOfRole);
    if(!applicationIdOfRole) return of([]);

    // Fetch scope end point of application.
    const applicationScopeEndPoint = this.getApplicationScopeEndPoint(applicationIdOfRole);
    // console.log("applicationScopeEndPoint:: ", applicationScopeEndPoint);
    if(!applicationScopeEndPoint) return of([]);

    return this.httpSrv.makeGetApiCall('GET_SCOPE_VALUES_OF_TYPE', applicationScopeEndPoint, {
      replaceParams: {
        REPLACE_SCOPE_TYPE: scope.scopeType,
        REPLACE_SCOPE_NAME: scope.scopeName
      }
    });
    // const scopeId = this.getScopeId(scope);
    // if(scopeId === this.getScopeId({scopeType: "NON_HIERARCHY", scopeName: 'Application'})) {
    //   return new Promise((res, rej) => res(this.applications));
    // }
    // return new Promise((res, rej) => res([]));
  }

  loadFilterOptions(roleId, filters) {
    // console.log("[loadFilterOptions]:: filters: ", filters);
    // let promises = filters.map(f => this.httpSrv.makeGetApiCall(
    //   'GET_SCOPE_VALUES', this.envService.baseUrl, {scopeType: f.scopeType, scopeName: f.scopeName}
    // ).toPromise());
    let promises = filters.map(f => this.fetchScopeValuesForFilter(roleId, f));

    forkJoin(promises)
    .subscribe((res: any) => {
      // console.log("LoadFilterOptions:: res: ", res);
      filters.forEach((f, i) => {
        const data = res[i];
        const filterOptions = data.response ? data.response  : [];
        this.filterOptions[this.getScopeId(f)] = filterOptions;
        // console.log("Filter Options ", this.filterOptions);
      });
    }, err => {
      console.log("Err:: ", err);
    })
  }


  //TODO: Need to have autocomplete search for all values.
  handleAutoCompleteSuggestions(index: number, filter: any) {
    // console.log("[handleAutoCompleteSuggestions]: ", index, filter);
    if(!index || !filter) return;

    filter.scopeValue = ['Search'];
  }
  /**============== End of scope changes ======================== */

  prepopulateUserData(data) {
    if(!data) return;

    const roles = (data.applications || []).filter(a => !!a.roleId).map(a => a.roleId);
    this.userForm.disable();
    this.scopeArray.clear();

    this.userForm.setValue({
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      // username: data.username || '',
      email: data.emailId || '',
      // mobileNo: data.mobileNo || '',
      status: isNullOrUndefined(data.status)? true : data.status,
      newRole: null,
      customer : this.customerName,
      scope: [] // TODO: Prepopulate scope
    });

    this.httpSrv.makeGetApiCall('GET_USER_PERMISSIONS', this.envService.baseUrl, {uriParams:  {userId: data.userId}})
    .subscribe(res => {
      // console.log("UserInfo:: ", res);
      // const roles =  
      const metaInfo = res.response.meta;
      const permissions = res.response && res.response.permissions ? res.response.permissions : {};
      this.userDbSnapshot = res.response;

      if (Object.keys(metaInfo).length > 0) {
        let { customerName, username, mobileNo, customerId } = metaInfo; 

        this.customerName = customerName;
        this.userName = username;
        this.mobileNumber = mobileNo;
        this.customerId = customerId;
      }

      // console.log("permissions:: ", permissions);
      let roles: any[] = [];
      for(let key in permissions) {
        // console.log('key:: ', key);
        let { applicationId, applicationName, roleId, roleName, userRoleScope } = permissions[key];
        roles.push({ applicationId, applicationName, roleId, roleName, userRoleScope });
        this.userRoles.push({ applicationId, applicationName, roleId, roleName, userRoleScope })
      }
      // console.log("role: ", roles);

      roles.forEach((r, i) => {
        this.selectedApplications[r.applicationId] = true;
        this.addRole(r.roleId);
        const scopeGroupValue = {
          roleId: r.roleId
        }
        if(r.userRoleScope && r.userRoleScope.scope) {
          // console.log("scope:: ", r.userRoleScope);
          r.userRoleScope.scope.forEach(s => {
            scopeGroupValue[s.scopeName] = s.scopeValue;
          })
        }
        this.prepopulateFiltersOfRole(i, scopeGroupValue);
      })
      this.userForm.enable();
    }, err => {
      console.log("error loading user info: ", err);
      this.userForm.enable();
    })
    // roles,
    // TODO: update filteredRoles depends on the user scope.
  }

  prepopulateFiltersOfRole(index: number, values: any) {
    if(isNullOrUndefined(index) || !values || !values.roleId) return;

    // console.log("[prepopulateFiltersOfRole]:: values: ", values);
    if(this.scopeArray.controls.length <= index) return;

    // console.log("Prepopulating");
    for(let key in values) {
      // console.log("key:: ", key);
      if(this.scopeArray.controls[index].get(key)) {
        // console.log("Updating ",key," value with ", values[key]);
        this.scopeArray.controls[index].get(key).setValue(values[key]);
      }
    }
  }

  // TODO: move this logic to directive.
  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //! Not in use.
  // Constraint: One role per application
  // If we select duplicate role in application then latest one will be saved.
  handleRoleChange(ev: any[]) {
    const selectedRole = ev[ev.length - 1];
    const roleIndex = ev.findIndex(r => r.roleId === selectedRole.roleId);
    const roles = roleIndex !== ev.length - 1 || roleIndex !== -1 ? ev.splice(roleIndex, 1) : ev;
    this.userForm.get('roles').setValue(roles.map((r: any) => r.roleId));
  }

  removeRole(role: any) {
    // console.log("Deleted Role:: ", role);
  }

  handleUpate() {
    // console.log("[HandleUpdate]:: ", this.userForm.value);
    if(this.userForm.invalid) {
      console.log("invalid edit form: ", this.userForm.errors);
      return;
    }

    // console.log("Calling Handle Update .....")

    const updatedUserInfo = this.userForm.value;
    const userInfo = {...this.modalData, email: this.modalData.emailId};

    const scope = updatedUserInfo.scope || [];
    const userRoleMapping = {};

    scope.forEach(s => {
      // fetch existing role info 
      // if not present create a new entry.
      userRoleMapping[s.roleId] = userRoleMapping[s.roleId] || {};

      // Get filter of role
      const filtersOfRole = this.getValidScopeOfRole(s.roleId);

      // Build scope of the role.
      const scope = [];
      filtersOfRole.forEach(filter => {
        const scopeObj = {
          scopeType: filter.scopeType,
          scopeName: filter.scopeName,
          scopeValue: s[filter.scopeName]
        }
        scope.push(scopeObj);
      });

      // Fetch existing scope if any.
      userRoleMapping[s.roleId].scope = userRoleMapping[s.roleId].scope || [];
      
      // add the current scope to the role.
      userRoleMapping[s.roleId].scope = userRoleMapping[s.roleId].scope.concat(scope);
    })
    // console.log("userRoleMapping:: ", userRoleMapping);

    let roleIdToNameMapping = {};
    this.roles.forEach(r => roleIdToNameMapping[r.roleId] = r.roleName);
    // console.log("RoleIdToNameMapping:: ", roleIdToNameMapping);

    // Compare the updatedRoleMapping with dbSnapshot
    // And move the roles to appropriate buckets(addRoles,UpdateRole,deleteRole)
    const existingPermissions = this.userDbSnapshot.permissions || {};
    const existingRolesOfUser = {};
    Object.values(existingPermissions).forEach((p:any) => existingRolesOfUser[p.roleId] = p);
    // console.log("existingRolesOfUser:: ", existingRolesOfUser);

    let addRoles = {};
    let updateRoles = {};
    let deleteRoles = [];

    // Find New roles
    // If role not found in existingPermissions then it's a new one.
    for(let role in userRoleMapping) {
      const roleName = roleIdToNameMapping[role];
      // console.log("Role name for ", role, " is:: ", roleName);
      if(existingRolesOfUser[role]) {
        // TODO: check for scope if scope chagned then only push it to updateRoles.
        // console.log("Existing role check for scope");
        updateRoles[role] = userRoleMapping[role]; 
      }

      if(!existingRolesOfUser[role]) {
        // console.log("new Role");
        addRoles[role] = userRoleMapping[role];
      }
    };

    // Find deleted Roles
    for(let role in existingRolesOfUser) {
      const roleName = roleIdToNameMapping[role];
      if(!userRoleMapping[role]) {
        // console.log("Delete role: ", roleName);
        deleteRoles.push(role);
      }
    }

    //TODO : Send meta information (username, mobileNo etc) only if these fields are changed
    let metaInfo = null;

    // console.log("Updated user info ", this.getMetaInfoStr(updatedUserInfo));
    // console.log("Old user info ", this.getMetaInfoStr(userInfo));

    let isMetaInfoChanged = this.getMetaInfoStr(updatedUserInfo) !== this.getMetaInfoStr(userInfo) ? true : false;
    // console.log(isMetaInfoChanged);

    if (isMetaInfoChanged) {

      metaInfo = updatedUserInfo;

      metaInfo = {
        ...metaInfo, 
        username : this.userName, 
        mobileNo : this.mobileNumber,
        customerName : this.customerName,
        customerId : this.customerId
      }
    }

  
    const postData = {
      metaInfo,
      addRoles,
      updateRoles,
      deleteRoles,
      userId: this.modalData.userId,
    }

    const details = {
      operation: USER_MGMT_CNST.OPERATION_TYPES.USER.EDIT_USER,
      postData
    };

    // console.log("Details ", details);

    this.appStateSrv.operationRef.next(details);
    this.activeModal.close();
  }


  getMetaInfoStr(userInfo: any) {
    if(!userInfo) return '';

    return JSON.stringify({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      status: userInfo.status
    })
  }
 
}