import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { HttpService, AppStateService, UtilsService } from 'src/app/services';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';
import { countryCodes } from 'src/app/constants/country-codes';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';

@Component({
  selector: 'app-add-customer-admin',
  templateUrl: './add-customer-admin.component.html',
  styleUrls: ['./add-customer-admin.component.scss']
})
export class AddCustomerAdminComponent implements OnInit {
  @Input() customerId : string;
  @Input() customerName : string;
  
  emailPattern: any = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  isEmailValid : boolean = true;

  countryCodeList : any[] = [...countryCodes];

  customerAdminForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', []),
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
  });

  isPrimaryMobileNoValid : boolean = true;
  currentCountryCode : string = "in";
  
  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService : EnvService
    ) { }

  ngOnInit() {
  }

  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;

  primaryMobileForm = new FormGroup({
		primaryMobileNo: new FormControl(undefined, [Validators.required]),
	});


  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  allowOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilsSrv.isEmailValid(this.emailPattern, inputStr);
  }

  addCustomerAdmin(){

    if (this.customerAdminForm.invalid) {
      return;
    }
    
    //* Create post data for add user
    const postdata = this.postDataCreationForAddUser();

    console.log("Post data ", postdata);

    //* Api call for add user
    this.httpSrv.makePostApiCall('ADD_USER', postdata, this.envService.baseUrl)
      .subscribe(res => {
        // console.log("Res ", res);
        if (res.result) {  
          this.updateUser(res.response, postdata);
        } else {
          this.utilsSrv.showToastMessage(
            USER_MGMT_CNST.ERROR_MESSAGES.CUST_ADMIN_GENERAL,
            "error"
          );
        }
      }, err => {
        
        // console.log("Error ", err);
        let errorMessage = USER_MGMT_CNST.ERROR_MESSAGES.CUST_ADMIN_GENERAL;

        if (!isNullOrUndefined(err)) {
          
          if (!isNullOrUndefined(err.error.message)) {

            let { KEYCLOAK_ERROR_MSGS } = USER_MGMT_CNST;

            if (err.error.message.startsWith(`${KEYCLOAK_ERROR_MSGS.incomingMobileNoError} '${postdata['metaInfo']['mobileNo']}'`)) {
              errorMessage = KEYCLOAK_ERROR_MSGS.displayedmobileNoErrorMsg;
            } else {
              errorMessage = err.error.message;
            }

          } else {
            
            let errorText = err.split(USER_MGMT_CNST.CONFLICT_STRING)[1];
  
            if (!isNullOrUndefined(errorText) && errorText !== '') {
              errorText = JSON.parse(errorText);
              errorMessage = errorText[0]["errorMessage"];
            }
          }
        }

        this.utilsSrv.showToastMessage(errorMessage, "error");
      })
  }

  checkLength(event: any) {
    let value = event.target.value;

    if (value.length > 0) {
      this.isPrimaryMobileNoValid = this.primaryMobileForm.invalid ? false : true;
    } else {
      this.isPrimaryMobileNoValid = true;
    }
  }

  resetField(event : Country) {
    this.primaryMobileForm.controls.primaryMobileNo.setValue(null);
    this.isPrimaryMobileNoValid = true;
    this.currentCountryCode = event.iso2;
  }

  //* post data creation for add user
  postDataCreationForAddUser(){
    const postdata = {
      metaInfo: {
        "username": this.customerAdminForm.value.username,
        "firstName": this.customerAdminForm.value.firstName,
        "lastName": this.customerAdminForm.value.lastName,
        "email": this.customerAdminForm.value.email,
        "mobileNo": this.primaryMobileForm.controls['primaryMobileNo'].value.e164Number,
        "customerId": this.customerId,
        "customerName": this.customerName 
      }
    }

    return postdata;
  }


  updateUser(userId: any, addUserPostData : any) {
  
    let postData = {
      "metaInfo": null,
      "addRoles": USER_MGMT_CNST.EDIT_USER_ADD_ROLE,
      "updateRoles": {},
      "deleteRoles": [],
      "userId": userId
  }
    this.httpSrv.makePutApiCall('UPDATE_USER', postData, this.envService.baseUrl, {
      uriParams: {
        userId
      }
    }).subscribe(res => {
      this.utilsSrv.showToastMessage(
        "Successfully added customer admin",
        "success"
      );   
      this.activeModal.close(this.customerAdminForm.value); 
    }, err => {
      this.utilsSrv.showToastMessage(
        "Error occured while creating customer admin",
        "error"
      );    
    })
  }

  onPaste(event: ClipboardEvent,key : any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z]/g, '');
    setTimeout(() => {
      trimmedText = trimmedText.substr(0,25);
      this.customerAdminForm.patchValue({
        [key]: trimmedText
     });
    }, 1);
}
}
