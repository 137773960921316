import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config, Path } from 'src/app/enums';
import { CommonService } from 'src/app/services/common.service';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { AppStateService, UtilsService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserUnauthorizedComponent } from '../popups/user-unauthorized/user-unauthorized.component';
import { USER_MGMT_CNST } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    error: string;
    statusCodes: any;
    errorMsgConfig = {}

    constructor(
        private cmnSrv: CommonService,
        private utilSrv : UtilsService,
        private authSrv: AuthenticationService,
        private toastrSrv : ToastrService,
        private appStateSrv : AppStateService,
        private modal : NgbModal
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap(() => {
                    // do anything with the response if necessary
                },
                async error => {
                    if (error instanceof HttpErrorResponse) {

                        this.errorMsgConfig = await this.cmnSrv.loadConfig({
                            path: Path.ERROR_MESSAGE_CONFIG_PATH,
                            configType: Config.ERROR_MESSAGE_CONFIG
                        })  

                        // console.log(this.errorMsgConfig);

                        if (error.status === 401) {
/*this code is commented by shiva for logging out if user permission is not available
                            let userPermissions = {};

                    
                            const modalRef = this.modal.open(UserUnauthorizedComponent, {...USER_MGMT_CNST.MODAL_OPTIONS, backdrop : 'static'});

                            modalRef.result.then((result: boolean) => {
                                if (result) {
                                    this.utilSrv.logout();
                                }
                            })
                            */
                            this.utilSrv.logout();
                            // this.toastrSrv.error(error.error.message, null, { disableTimeOut: true});

                            // If the url is for getting user permissions then check if the
                            // logged in user has any permissions, if empty don't redirect to login
                            // setTimeout(() => {
                            //     userPermissions = this.appStateSrv.getUserPermissions();
                            // }, 200);

                            // console.log("User permissions in error-service ", userPermissions);

                            

                            // if (!request.url.includes('user-permissions')) {
                                // setTimeout(() => {
                                //     this.utilSrv.logout();
                                // }, 5000);
                            // }

                        } else {
                            const errorMessage = this.setError(error);
                            return throwError(errorMessage);
                        }
                    }
                }))
    }

    setError(err: HttpErrorResponse): string | Observable<any> {
        // console.log(this.errorMsgConfig);
        let errorMessage = this.errorMsgConfig['DefaultErrorMessage']['message'];
        
        if (err.error instanceof ErrorEvent) {
            //client side error
            errorMessage = err.message
        } else {
            //server side error
            const loggedIn = this.authSrv.isLoggedIn();
            errorMessage = err.error.message ? err.error.message : this.errorMsgConfig[err.status]['message'];

            if (err.status === 401) {
                return errorMessage;
            }

            if (err.status === 400 && loggedIn) {
                errorMessage = err.error.message
                    ? { message: err.error.message, code: err.error.statusCode }
                    : this.errorMsgConfig[err.status]['message'];
            }

        }
        return errorMessage;
    }
}