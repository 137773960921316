import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import * as Keycloak from "keycloak-js";
import { Observable, from } from "rxjs";
import { switchMap } from 'rxjs/operators';
import { USER_MGMT_CNST } from 'src/app/constants';
import { AuthenticationService } from "src/app/modules/authentication/services/authentication.service";
import { AppStateService, UtilsService } from "src/app/services";
import { CsrfTokenService } from "src/app/services/csrf-token.service";
import { isNullOrUndefined } from "util";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private authSrv: AuthenticationService,
    private csrfTokenSrv : CsrfTokenService,
    private utilSrv : UtilsService,
    private appStateSrv : AppStateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.fetchDetails(request))
      .pipe(
        switchMap((result : any) => {
          // currentUser = USER_MGMT_CNST.ACCESS_TYPE == USER_MGMT_CNST.ACCESS_TYPE_LIST.LOCAL ? this.dataSrv.getUserData() : currentUser;

          if (isNullOrUndefined(result)) {
            return next.handle(request);
          }

          let csrfToken = this.csrfTokenSrv.getCsrfToken();
          let isCsrfGenerationUrl = request.url.includes(USER_MGMT_CNST.API_MAPPING.GET_CSRF_TOKEN);
          
          if (!isNullOrUndefined(result.currentUser) && result.currentUser["accessToken"]) {

            let additionalHeaders : HttpHeaders;

            //* If the request is to generate csrf token don't include
            //* csrf token in header else include it for every request
            additionalHeaders = this.buildAdditionalHeaders(isCsrfGenerationUrl, result.currentUser, csrfToken);
         
            let modifiedRequest = request.clone({headers : additionalHeaders});

            //*Append user id to the request only to the required endpoints
            if (USER_MGMT_CNST.DOMAINS_NEEDING_USER_ID.findIndex(item => request.url.includes(item)) !== -1) {
              modifiedRequest = modifiedRequest.clone({
                params: (request.params ? request.params : new HttpParams()).set(
                  "email",
                  `${result["currentUser"]["userId"]}`
                )
              })
            }

            return next.handle(modifiedRequest);
          }
          else {
            return next.handle(request);
          }
        })
      )
  }

  buildAdditionalHeaders(isCsrfGenerationUrl: boolean, currentUser: unknown, csrfToken: string) {
    let additionalHeaders : HttpHeaders;
    csrfToken = isNullOrUndefined(csrfToken) ? localStorage.getItem('csrfToken') : csrfToken;

    if (isCsrfGenerationUrl) {
      additionalHeaders = new HttpHeaders({
        'Authorization': `Bearer ${currentUser["accessToken"]}`
      });
    } else if (!isNullOrUndefined(csrfToken)) {
      additionalHeaders = new HttpHeaders({
        'Authorization': `Bearer ${currentUser["accessToken"]}`,
        'Csrf-Token': csrfToken
      });
    }

    return additionalHeaders;
  }

  loadUserInfo() {
    return {
      accessToken: this.authSrv.getToken(),
      userId: this.authSrv.getUserId()
    };
  }

  fetchDetails(request: HttpRequest<any>) {
    let keyCloakInstance = this.appStateSrv.getKeyCloakInstance();

    let result = {
      currentUser: null,
      token : null
    }

    return new Promise(async resolve => {
      // setTimeout(async () => {

        if (request.url.includes('json')) {
          resolve(null);
        }

        //* Before invoking an api request check the validity of the current 
        //* access token. If the validity is less than 'x' number of seconds, 
        //* then the token is refreshed before invoking the request.
        //* 'x' - SECONDS_ELAPSED_FOR_TOKEN_REFRESH in proj.cnst.ts
        let isTokenRefreshed : boolean | null = !isNullOrUndefined(keyCloakInstance)
           ? await this.checkForTokenExpiryAndRefresh(keyCloakInstance) 
           : null;

        let existingToken : string | null = localStorage.getItem('token');

        let finalToken : string = !isNullOrUndefined(isTokenRefreshed) && isTokenRefreshed 
          ? keyCloakInstance.token 
          : existingToken === 'null' 
          ? JSON.parse(existingToken)
          : existingToken;

        if (!isNullOrUndefined(keyCloakInstance) && !isNullOrUndefined(keyCloakInstance.token)) {
          if (existingToken !== keyCloakInstance.token) {
            finalToken = keyCloakInstance.token;
            localStorage.setItem('token', finalToken);
          }
        }
          
        let userInfo = this.loadUserInfo();

        result.currentUser = userInfo;
        result.token = finalToken;
        
        resolve(result);
      // }, 500);
    })
  };

  //* Resolves to true / false if token was refreshed or not
  //* Resolves to null in case of errors / keycloak instance is null
  checkForTokenExpiryAndRefresh(keyCloakInstance: Keycloak.KeycloakInstance): Promise<any> {
    let promise = new Promise(async (resolve) => {

      if (!isNullOrUndefined(keyCloakInstance)) {

        //* Check if refresh token has not expired before
        //* checking validity of access token
        keyCloakInstance.onAuthRefreshError = () => {
          this.utilSrv.showToastMessage("Refresh Token Expired. Logging out", "error");
          setTimeout(() => { this.utilSrv.logout() }, 1000);
        }
 
        //* Returns true if token is refreshed, false otherwise
        let isTokenRefreshed = await keyCloakInstance
          .updateToken(20)
          .catch(() => resolve(null));

        resolve(isTokenRefreshed);
      } else {
        resolve(null);
      }
    })
    return promise;
  }
}
