import { Injectable } from '@angular/core';
import { DropDownConfig } from '../interfaces/dropdown.config';
import { KeyCloakOptions } from '../interfaces/keycloak-options.config';

import { KeyCloakUrls } from '../interfaces/keycloak-urls.config';

@Injectable({
  providedIn: 'root'
})

//* Represents the environment variables for the project that can be
//* injected anywhere in our application using Angular dependency injection

//? If env.js cannot be loaded, the application will fall back to 
//? the default values defined in this class.
export class EnvService {

  // The values that are defined here are the default values
  // that can be overridden by env.js
  public keyCloakUrls: KeyCloakUrls = {
    baseUrl: 'http://localhost:8080/auth',
    redirectUrl: 'http://localhost:5200/users',
    logoutUrl: 'http://localhost:5200/authentication/login'
  }

  public keyCloakOptions: KeyCloakOptions = {
    clientId: 'angular-app',
    realm: 'sample',
    keyCloakUrls: {
      baseUrl: this.keyCloakUrls.baseUrl,
      redirectUrl: this.keyCloakUrls.redirectUrl,
      logoutUrl: this.keyCloakUrls.logoutUrl
    }
  }

  public scopeBaseUrl : string = 'http://localhost:5000/api/v1/scope/';

  public baseUrl : string = 'http://localhost:8081/';

  public kredosUrl : string = 'http://localhost:8082/app1/kreedos/';

  public appRedirectUrl : string = 'oneportal';

  public userMgmtRedirectUrl : string = 'users';

  public copyRightText : string = 'Moove';

  public headerAppName : string = 'One Portal';

  public isCustomerDropdownRequired : boolean = false;

  public customerDropDownValues : DropDownConfig[] = [];
}
